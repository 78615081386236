import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import PrivacyModal from "./PrivacyModal";
import { IMAGE_PATHS, DOMAIN_URL } from '../../Functions/Constant';
import MediaUpload from '../../Components/PostCreation/MediaUpload';
import MediaPreview from '../../Components/PostCreation/MediaPreview';
const PostModal = ({ show, handleClose, handlePost, userData }) => {
    const [file, setFile] = useState(null);
    const [Originalfile, setOriginalFile] = useState(null);
    const [fileType, setFileType] = useState("");
    const [postText, setPostText] = useState("");
    const [error, setError] = useState("");
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    const [privacy, setPrivacy] = useState("Connections only");
    const [selectedTag, setSelectedTag] = useState(null); // Store a single selected tag
    const [mediaFiles, setMediaFiles] = useState([]);
    const handleMediaUpload = (files) => {
        const newFiles = Array.from(files).map((file) => ({
            file,
            url: URL.createObjectURL(file),
        }));
        setMediaFiles((prev) => [...prev, ...newFiles]);
    };
    const handleRemoveMedia = (index) => {
        setMediaFiles((prev) => prev.filter((_, i) => i !== index));
    };
    // Dummy tags
    const tags = ["Salary", "Promotion", "Work", "Freelance", "Internship"];

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const newselectedFile = e.target.files[0];

        if (selectedFile) {
            const fileSizeMB = selectedFile.size / 1024 / 1024; // Convert bytes to MB
            const fileType = selectedFile.type;
            const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];

            if (!allowedFormats.includes(fileType)) {
                setError("Only JPG, JPEG, and PNG files are allowed.");
                setFile(null);
                return;
            }

            if (fileSizeMB > 1) {
                setError("File size must be less than 1MB.");
                setFile(null);
                return;
            }
            setFile(URL.createObjectURL(selectedFile));
            setOriginalFile(newselectedFile); // ✅ Create a new reference
            setFileType(fileType);
            setError(""); // Clear any previous error

        }
    };

    const handleSubmit = async () => {
        if (!postText.trim()) return;
        let attachments = [];
        let mainMedia = { type: '', url: '' };
        // Step 1: Upload media files to the server if there are any
        //   if (mediaFiles.length > 0) {
        //     const formData = new FormData();
        //     mediaFiles.forEach((media) => {
        //       formData.append('files', media.file); // Append each file to FormData
        //     });

        //     // Upload files to the server
        //     const uploadResponse = await axios.post('https://api.jobcritics.com/api/streaks/upload', formData, {
        //       headers: {
        //         'Content-Type': 'multipart/form-data'
        //       }
        //     });

        //     // Get the URLs of the uploaded files
        //     attachments = uploadResponse.data.urls;

        //     // Set the main media (first file)
        //     mainMedia = {
        //       type: mediaFiles[0].file.type.startsWith('image/') ? 'image' : 'video',
        //       url: attachments[0]
        //     };
        //   }
        console.log(Originalfile);
        handlePost({ file: file, fileType: fileType, text: postText, tag: selectedTag, privacy: privacy, originalFile: mediaFiles }); // Pass selected tag in form submission
        handleClose();
        setFile(null);
        setOriginalFile(null);
        setPostText("");
        setSelectedTag(null); // Clear selected tag after posting
    };

    const handlePrivacySelect = (selectedPrivacy) => {
        setPrivacy(selectedPrivacy);
        setShowPrivacyModal(false);
    };
    const handleModalClose = () => {
        setFile(null);
        setFileType("");
        setPostText("");
        setError("");
        setSelectedTag(null);
        setShowPrivacyModal(false);
        handleClose(); // Close modal
    };




    return (
        <Modal show={show} onHide={handleModalClose} centered size="lg">
            <Modal.Body className="p-4 rounded-lg shadow-lg relative bg-white">
                {/* Header Section with Close Button */}
                <Modal.Header closeButton className="p-0 items-start border-none">
                    <div className="mb-3">
                        <div
                            className="flex items-center p-2 rounded-lg transition-all duration-300 hover:bg-gray-200 cursor-pointer"
                            onClick={() => setShowPrivacyModal(true)} // Open Privacy Modal on Click
                        >
                            <img
                                src={userData?.profile_image || IMAGE_PATHS.USER}
                                className="w-[50px] h-[50px] rounded-full border border-gray-300 mr-3"
                                alt="User"
                            />
                            <div>
                                <h4 className="text-lg font-bold text-gray-900 mb-0 flex items-center">
                                    {userData?.name}
                                    <i className="fas fa-chevron-down text-gray-500 ml-1 text-sm"></i>
                                </h4>
                                <p className="text-sm text-gray-600">{privacy}</p>
                            </div>
                        </div>
                    </div>
                </Modal.Header>

                {/* Post Textarea */}
                <Form.Group>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="What do you want to talk about?"
                        value={postText}
                        style={{ resize: "none" }}
                        maxLength={1000} // Prevent typing beyond 1000 characters
                        onChange={(e) => {
                            if (e.target.value.length <= 1000) {
                                setPostText(e.target.value);
                            }
                        }}
                        className="form-control cl-b text-lg border-none focus:ring-0 focus:outline-none"
                    />

                    {/* Validation Message (if character limit reaches 1000) */}
                    {postText.length >= 1000 && (
                        <p className="text-red-500 text-xs mt-1">Maximum 1000 characters allowed.</p>
                    )}
                </Form.Group>
                <MediaPreview mediaFiles={mediaFiles} onRemove={handleRemoveMedia} />



                {/* File Preview */}
                {file && (
                    <div className="relative mb-3 mt-3">
                        <button
                            onClick={() => setFile(null)}
                            className="absolute top-2 right-0 w-[2.0rem] h-[2.0rem] text-black rounded-full hover:bg-gray-200 transition-all"
                        >
                            <i className="fas fa-times text-lg text-gray-500 hover:text-gray-600"></i> {/* Close Icon */}
                        </button>
                        <img src={file} alt="Uploaded preview" className="w-full rounded-md" />
                    </div>
                )}



                

                {/* Submit Button */}
                <div className="flex justify-between items-start border-t pt-4 mt-3">
                    <span className="flex gap-2">
                        <MediaUpload
                            onUpload={handleMediaUpload}
                            mediaFiles={mediaFiles}
                            setError={setError}
                        />
                       


                        {/* Tags Selection & File Upload */}
                        <div className="flex items-center flex-wrap gap-3">

                            {/* Tag Options (Only One Selectable at a Time) */}
                            <div className=" flex flex-wrap gap-2">
                                {tags.map((tag, index) => (
                                    <button
                                        key={index}
                                        onClick={() => setSelectedTag(tag)} // Only one tag at a time
                                        className={`px-3 py-1 text-sm font-medium border border-gray-300 rounded-full cursor-pointer transition-all duration-200 leading-[1.4]
                ${selectedTag === tag ? "bg-gray-300 text-gray-900" : "bg-white text-gray-600 hover:bg-gray-200"}`}
                                    >
                                        {tag}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </span>
                    <Button
                        className={` ${!postText.trim() || postText.length >= 1000
                            ? "btn-explore-yellow-disabled !border-gray-300 !border-[1px] disabled:!cursor-not-allowed disabled:!bg-gray-300 disabled:opacity-50 disabled:hover:!border-gray-300 disabled:hover:!bg-gray-300 disabled:!text-white disabled:hover:!text-white" : "btn-explore-yellow"
                            }`}
                        onClick={handleSubmit}
                        disabled={postText.length >= 1000 || !postText.trim()}
                    >
                        Post
                    </Button>
                </div>
                {error && <p className="text-red-500 text-sm mt-1">{error}</p>}

                {/* Privacy Modal */}
                <PrivacyModal
                    show={showPrivacyModal}
                    handleClose={() => setShowPrivacyModal(false)}
                    handlePrivacySelect={handlePrivacySelect}
                />
            </Modal.Body>
        </Modal>
    );
};

export default PostModal;
