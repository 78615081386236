// src/components/MediaPreview/MediaPreview.js
import React from 'react';
import './MediaPreview.css';

const MediaPreview = ({ mediaFiles, onRemove }) => {
  if (mediaFiles.length === 0) return null;

  return (
    <div className="media-preview">
      {mediaFiles.map((media, index) => (
        <div key={index} className="media-item">
          {media.file.type.startsWith('image/') ? (
            <img src={media.url} alt="Preview" className="media-image" />
          ) : (
            <video src={media.url} controls className="media-video" />
          )}
          <button
            className="remove-media-button"
            onClick={() => onRemove(index)}
          >
            ✕
          </button>
        </div>
      ))}
    </div>
  );
};

export default MediaPreview;