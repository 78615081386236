import React, { useState } from "react";

function TextWithReadMore({ text }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const words = text.split(" "); // Split text into words
  const shortText = words.slice(0, 40).join(" "); // First 40 words
  const isLongText = words.length > 40; // Check if text exceeds 40 words

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {isExpanded || !isLongText ? text : `${shortText}...`}
      {isLongText && (
        <span
          onClick={toggleReadMore}
          className="text-black font-medium ml-2 cursor-pointer hover:underline"
        >
          {isExpanded ? "Read Less" : "Read More"}
        </span>
      )}
    </>
  );
}

export default TextWithReadMore;