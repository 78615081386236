// src/components/PostCreationModal/PostCreationModal.js
import React, { useState } from 'react';
import './PostCreationModal.css';
import MediaUpload from './MediaUpload';
import MediaPreview from './MediaPreview';

const PostCreationModal = ({ onClose }) => {
  const [postContent, setPostContent] = useState('');
  const [mediaFiles, setMediaFiles] = useState([]);
  const [visibility, setVisibility] = useState('Anyone');

  const handleMediaUpload = (files) => {
    const newFiles = Array.from(files).map((file) => ({
      file,
      url: URL.createObjectURL(file),
    }));
    setMediaFiles((prev) => [...prev, ...newFiles]);
  };

  const handleRemoveMedia = (index) => {
    setMediaFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handlePost = () => {
    // Handle post submission logic here (e.g., API call)
    console.log('Post Content:', postContent);
    console.log('Media Files:', mediaFiles);
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <div className="user-info">
            <img
              src="https://via.placeholder.com/40"
              alt="User"
              className="user-avatar"
            />
            <div>
              <h4>Rajeev Parshad</h4>
              <select
                value={visibility}
                onChange={(e) => setVisibility(e.target.value)}
              >
                <option value="Anyone">Post to Anyone</option>
                <option value="Connections">Connections Only</option>
                <option value="Group">Group</option>
              </select>
            </div>
          </div>
          <button className="close-button" onClick={onClose}>
            ✕
          </button>
        </div>

        <div className="modal-body">
          <textarea
            placeholder="What do you want to talk about?"
            value={postContent}
            onChange={(e) => setPostContent(e.target.value)}
            className="post-textarea"
          />
          <MediaPreview mediaFiles={mediaFiles} onRemove={handleRemoveMedia} />
          <MediaUpload onUpload={handleMediaUpload} />
        </div>

        <div className="modal-footer">
          <button className="ai-rewrite-button">
            <span role="img" aria-label="sparkle">
              ✨
            </span>{' '}
            Rewrite with AI
          </button>
          <button
            className="post-button"
            onClick={handlePost}
            disabled={!postContent && mediaFiles.length === 0}
          >
            Post
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostCreationModal;