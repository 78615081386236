import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import { Modal, Button } from "react-bootstrap";

const ConfirmationPopupWithLink = ({
    isOpen,
    title,
    message,
    onClose,
    onConfirm = false,
    onDiscard = false,
    loading,
    showCancelButton = true,
    onCreateLink,
    linkToCopy = "https://chatgpt.com/share/…" // default/fallback
}) => {
    const [showLoader, setShowLoader] = useState(true);

    const fallbackCopy = (text) => {
        const textarea = document.createElement("textarea");
        textarea.value = text;
        textarea.style.position = "fixed"; // avoid scroll jump
        textarea.style.top = "-9999px";
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
    
        try {
            const successful = document.execCommand("copy");
            if (successful) {
                onCreateLink?.();
            } 
        } catch (err) {
            console.error("Fallback copy failed", err);
        }
    
        document.body.removeChild(textarea);
    };
    
    useEffect(() => {
        if (isOpen) {
            document.documentElement.style.overflow = "hidden";
            document.body.style.overflow = "hidden";

            setShowLoader(true);
            const timer = setTimeout(() => {
                setShowLoader(false);
            }, 1000);

            return () => {
                clearTimeout(timer);
                document.documentElement.style.overflow = "auto";
                document.body.style.overflow = "auto";
            };
        }
    }, [isOpen]);
    

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-d">
            {showLoader ? (
                <Loader />
            ) : (
                <div className="bg-white p-6 rounded-2xl shadow-xl w-[520px] relative flex flex-col">
                    {/* Close Button */}
                    <button
                        className="absolute top-2 right-3 w-[2.0rem] h-[2.0rem] text-black rounded-full hover:bg-gray-200 transition-all"
                        onClick={onClose}
                        disabled={loading}
                    >
                        <svg
                            className="svg-icon"
                            style={{
                                height: "28px",
                                verticalAlign: "middle",
                                fill: "currentColor",
                                overflow: "hidden",
                            }}
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M704.28672 309.20704l28.95872 28.9792L334.6432 736.78848l-28.95872-28.9792z" fill="#231815" />
                            <path d="M341.03296 315.5968l398.60224 398.60224-28.95872 28.95872-398.60224-398.60224z" fill="#231815" />
                        </svg>
                    </button>

                    {/* Title & Message */}
                    {/* <h6 className="font-semibold text-black text-left w-full mb-3">{title}</h6> */}
                    <p className="text-gray-600 text-left w-full mb-3 flex items-center justify-center pt-[36px]">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="signal-success-large" aria-hidden="true" role="none" data-supported-dps="64x64" width="64" height="64">
                            <circle cx="32" cy="32" r="32" fill="#9db88f" />
                            <circle cx="32" cy="32" r="24" fill="#fff" />
                            <circle cx="32" cy="32" r="21" fill="#daebd1" />
                            <path d="M42.86 22.64c-1.3-1.02-3.19-.8-4.21.5l-9.93 12.42-4.59-4.41c-1.17-1.17-3.07-1.17-4.24 0a3 3 0 000 4.24l7.06 6.77a2.983 2.983 0 004.4-.29l12.02-15.02c1.02-1.3.8-3.19-.5-4.21z" fill="#38434f" />
                        </svg></p>
                    <p className="text-gray-600 text-center pb-[24px]">{message}</p>
                    <p className="text-black border-t  border-[#ede8e8] px-1 py-1 mb-3 mt-3">
                        Share your profile link to receive ratings and enhance your professional credibility.
                    </p>

                    {/* Link field & button styled like input group */}
                    <div className="d-flex align-items-center border rounded-[30px] px-3 py-2 mb-3" style={{ border: '1px solid #dee2e6' }}>
                        <span className="text-dark flex-grow-1 small">https://chatgpt.com/share/…</span>
                        <Button
                            onClick={() => {
                                if (navigator.clipboard && navigator.clipboard.writeText) {
                                    navigator.clipboard.writeText(linkToCopy)
                                        .then(() => {
                                            onCreateLink?.();
                                        })
                                        .catch((err) => {
                                            console.error("Clipboard API failed", err);
                                            fallbackCopy(linkToCopy);
                                        });
                                } else {
                                    fallbackCopy(linkToCopy);
                                }
                            }}
                            
                            
                            className="btn-explore-yellow"
                        >
                            🔗 Copy link
                        </Button>
                    </div>

                    {/* Buttons */}
                    <div className="flex mt-6 w-full justify-center">
                        {showCancelButton ? (
                            <>

                            </>
                        ) : (
                            <button className="btn-explore-yellow rs" onClick={onConfirm} disabled={loading}>
                                OK
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ConfirmationPopupWithLink;
