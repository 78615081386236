import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Container } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Accordions from '../../Components/Accordion/AccordionRatingView'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import API from '../../Api/Api';
import { getStarColorClass, getRatingLabel } from '../../Functions/RatingLabelColor';
import InfoTooltip from '../../Components/InfoTooltip'
import ConfirmationPopup from '../../Components/ConfirmationPopup'
import StarRating from '../../Components/StarRating'
import ImagePopup from '../../Components/imagePreview'
import TextWithReadMore from '../../Components/Readmore'
import ShareLinkModal from "../../Components/ShareLinkModal";
import ConfirmPopupShareLink from '../../Components/ConfirmPopupShareLink'


import { IMAGE_PATHS, DOMAIN_URL } from '../../Functions/Constant';


const Acedmic = ({ scrollToReviews, academic, academicRatings }) => {
    const [showModal, setShowModal] = useState(false);
    const [activityCount, setActivityCount] = useState(0);
    const [descriptionCount, setDescriptionCount] = useState(0);
    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const [languageProficiency, setLanguageProficiency] = useState("");
    const [suggestedLanguages, setSuggestedLanguages] = useState([]);
    const [locationsuggestions, setLocationSuggestions] = useState([]);
    const [fileName, setFileName] = useState("");
    const [fileSize, setFileSize] = useState("");
    const [file, setFile] = useState(null);
    const formikRef = useRef(null);
    const [suggestions, setSuggestions] = useState([]);
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [education, setEducation] = useState([]);
    const [language, setLanguage] = useState([]);
    const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);
    const [degreeQuery, setDegreeQuery] = useState("");
    const [fieldQuery, setFieldQuery] = useState("");
    const [degreeSuggestions, setDegreeSuggestions] = useState([]);
    const [fieldSuggestions, setFieldSuggestions] = useState([]);
    const [showPopupC, setShowPopupC] = useState(false);
    const [showPopupCe, setShowPopupCe] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [showModalE, setShowModalE] = useState(false);
    const [educationData, setEducationData] = useState({
        school: "",
        degree: "",
        fieldOfStudy: "",
        startMonth: "",
        startYear: "",
        startYear: "",
        isCurrentlyWorking: false,
        endYear: "",
        location: "",
        grade: "",
        activities: "",
        description: "",
    });
    const [showOtherSchool, setShowOtherSchool] = useState(false);
    const [otherSchool, setOtherSchool] = useState("");
    const [languageQuery, setLanguageQuery] = useState("");
    const [languageSuggestions, setLanguageSuggestions] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isEditModeLm, setIsEditModeLm] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupL, setShowPopupL] = useState(false);
    const [selectedValueL, setSelectedValueL] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [locationquery, setLocationquery] = useState("");
    const [showFull, setShowFull] = useState(false);
    const [languageData, setLanguageData] = useState({

        language: "",
        otherLanguage: "",
        languageProficiency: "",
        proficiency: "",
        testName: "",
        totalScore: "",
        readingScore: "",
        listeningScore: "",
        writingScore: "",
        speakingScore: "",
        completion_year: "",
        completion_month: "",
        scoreCard: null,
        employee_language_id: ""
    });

    const handlePreview = (url) => {
        setImagePreview(`${DOMAIN_URL}/${url}`); // ✅ Correct URL
    };

    const [popupData, setPopupData] = useState({
        isOpen: false,
        title: "",
        message: "",
        onClose: () => { },
        onConfirm: () => { }
    });
    const [popupDataE, setPopupDataE] = useState({
        isOpen: false,
        message: "",
        onClose: () => { },
        onConfirm: () => { }
    });

    useEffect(() => {
        fetchEducation();
        fetchLanguage();
        const handleKeyDown = (e) => {
            if (e.key === "Escape") {
                setSuggestedLanguages([]);
                setDegreeSuggestions([]);
                setFieldSuggestions([]);
            }
        };
        //console.log("sxzvdsvdsvsdvsdvsd", academic);
        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, []);

    const fetchEducation = async () => {
        const response = await API.get(`/education`);
        setEducation(response.education);
        console.log(response);
    };

    const fetchLanguage = async () => {
        const response = await API.get(`/language`);
        setLanguage(response.languages);
        console.log(response);
    };

    // Validation Schema
    const validationSchema = Yup.object().shape({
        school: Yup.string().required("University/Institution Name is required")
            .test("wordCount", "Field of Study must be at most 10 words", (value) =>
                value ? value.trim().split(/\s+/).length <= 10 : true
            ),
        degree: Yup.string().required("Degree/Certification is required"),
        endMonth: Yup.string().when("isCurrentlyWorking", {
            is: false,
            then: (schema) => schema.required("End month is required"),
            otherwise: (schema) => schema.notRequired().nullable(),
        }),
        endYear: Yup.string().when("isCurrentlyWorking", {
            is: false,
            then: (schema) => schema.required("End year is required"),
            otherwise: (schema) => schema.notRequired().nullable(),
        }),

        fieldOfStudy: Yup.string()
            .required("Study/Major is required")
            .test("wordCount", "Field of Study must be at most 10 words", (value) =>
                value ? value.trim().split(/\s+/).length <= 10 : true
            ),
        startMonth: Yup.string().required("Start month is required"),
        startYear: Yup.string().required("Start year is required"),
        grade: Yup.string()
            .matches(/^\d+(\.\d+)?$/, "Grade must be a number and can have up to one decimal point") // Allows numbers with optional decimal
            .max(5, "Grade must be at most 5 characters"),
        activities: Yup.string()
            .transform((value) => (value ? value.trim() : "")) // Trim whitespace
            .test(
                "word-count",
                "Max 500 words allowed",
                (value) => !value || value.split(/\s+/).filter(word => word !== "").length <= 500
            ),


        // description: Yup.string().max(500, "Max 500 characters"),
        location: Yup.string().required("Location is required"),
    });


    const languageSchema = Yup.object().shape({
        language: Yup.string().required("Language is required"),

        languageProficiency: Yup.string()
            .oneOf(["yes", "no"], "Please select proficiency")
            .required("Please select proficiency"),

        // If "no", then Proficiency is required
        proficiency: Yup.string().when("languageProficiency", {
            is: "no",
            then: (schema) => schema.required("Proficiency is required"),
            otherwise: (schema) => schema.notRequired(),
        }),

        // If "yes", then Certification-related fields are required
        testName: Yup.string().when("languageProficiency", {
            is: "yes",
            then: (schema) => schema.required("Test Name is required"),
            otherwise: (schema) => schema.notRequired(),
        }),

        totalScore: Yup.number()
            .typeError("Total score must be a number")
            .when("languageProficiency", {
                is: "yes",
                then: (schema) => schema.required("Total score is required"),
                otherwise: (schema) => schema.notRequired(),
            }),

        readingScore: Yup.number()
            .typeError("Reading score must be a number")
            .when("languageProficiency", {
                is: "yes",
                then: (schema) => schema.required("Reading score is required"),
                otherwise: (schema) => schema.notRequired(),
            }),

        listeningScore: Yup.number()
            .typeError("Listening score must be a number")
            .when("languageProficiency", {
                is: "yes",
                then: (schema) => schema.required("Listening score is required"),
                otherwise: (schema) => schema.notRequired(),
            }),

        writingScore: Yup.number()
            .typeError("Writing score must be a number")
            .when("languageProficiency", {
                is: "yes",
                then: (schema) => schema.required("Writing score is required"),
                otherwise: (schema) => schema.notRequired(),
            }),

        speakingScore: Yup.number()
            .typeError("Speaking score must be a number")
            .when("languageProficiency", {
                is: "yes",
                then: (schema) => schema.required("Speaking score is required"),
                otherwise: (schema) => schema.notRequired(),
            }),


        completion_month: Yup.string().when("languageProficiency", {
            is: "yes",
            then: (schema) => schema.required("Completion month is required"),
            otherwise: (schema) => schema.notRequired(),
        }),
        completion_year: Yup.string().when("languageProficiency", {
            is: "yes",
            then: (schema) => schema.required("Completion year is required"),
            otherwise: (schema) => schema.notRequired(),
        }),
        //
        // completionYear: Yup.number()
        //     .typeError("Completion year must be a valid number")
        //     .when("languageProficiency", {
        //         is: "yes",
        //         then: (schema) => schema.required("Completion year is required"),
        //         otherwise: (schema) => schema.notRequired(),
        //     }),
        //
        scoreCard: Yup.mixed().when("languageProficiency", {
            is: "yes",
            then: (schema) =>
                schema.required("Score card is required").nullable(),
            otherwise: (schema) => schema.notRequired().nullable(),
        }),
    });


    const fetchSchool = async (search) => {
        if (!search) {
            setSuggestions([]);
            return;
        }
        setLoading(true);
        try {
            console.log("📡 Calling API with query:", search);
            const response = await API.get(`/autocomplete/school?query=${search}`);
            const schoolList = Array.isArray(response) ? response : [];
            setSuggestions(schoolList); // Update state with extracted list
        } catch (error) {
            setSuggestions([]);
        } finally {
            setLoading(false);
        }
    };
    // Fetch Degree Suggestions
    const fetchDegrees = async (search) => {
        if (!search) {
            setDegreeSuggestions([]);
            return;
        }
        setLoading(true);
        try {
            const response = await API.get(`/autocomplete/degrees?query=${search}`);
            setDegreeSuggestions(response);
        } catch (error) {
            setDegreeSuggestions([]);
        } finally {
            setLoading(false);
        }
    };

    // Fetch Field of Study Suggestions
    const fetchFieldsOfStudy = async (search) => {
        if (!search) {
            setFieldSuggestions([]);
            return;
        }
        setLoading(true);
        try {
            const response = await API.get(`/autocomplete/fields_of_study?query=${search}`);
            setFieldSuggestions(response);
        } catch (error) {
            setFieldSuggestions([]);
        } finally {
            setLoading(false);
        }
    };
    const getRatingLabel = (rating) => {
        if (rating >= 4.5) return "Excellent";
        if (rating >= 3.5) return "Good";
        if (rating >= 2.5) return "Average";
        if (rating >= 1.5) return "Poor";
        return "Terrible";
    };
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setLoading(true);
        try {
            //console.log(values);
            const payload = values.educationId && values.educationId > 0 ? {
                school: values.school,
                degree: values.degree,
                fieldOfStudy: values.fieldOfStudy,
                start_month: values.startMonth != "" ? values.startMonth : null,
                start_year: values.startYear,
                end_month: values.isCurrentlyWorking ? null : values.endMonth,
                end_year: values.isCurrentlyWorking ? null : values.endYear,
                is_current: values.isCurrentlyWorking ? 1 : 0,
                grade: values.grade,
                location: values.location,
                activities: values.activities,
                description: '',
                education_id: values.educationId
            } : {
                school: values.school,
                degree: values.degree,
                fieldOfStudy: values.fieldOfStudy,
                location: values.location,
                start_month: values.startMonth,
                start_year: parseInt(values.startYear),
                end_month: values.isCurrentlyWorking ? null : values.endMonth,
                end_year: values.isCurrentlyWorking ? null : values.endYear,
                is_current: values.isCurrentlyWorking ? 1 : 0,
                grade: values.grade,
                activities: values.activities,
                description: '',
            }


            console.log("Submitting Payload:", payload);
            if (values.educationId && values.educationId > 0) {
                const response = await API.put(`/education/${values.educationId}`, payload);
            } else {
                const response = await API.post('/education', payload);
            }
            setPopupDataE({
                isOpen: true,
                message: "Your Profile Has been Updated.",
                onConfirm: () => setPopupDataE({ isOpen: false }), // Close Popup on Confirm
                onClose: () => setPopupDataE({ isOpen: false })    // Close Popup on Close
            });
            fetchEducation();
            resetForm();
            setShowModal(false);
        } catch (error) {
            console.error(" Error submitting form:", error);
        } finally {
            setLoading(false);
            setSubmitting(false);
        }
    };
    const addSchool = () => {
        setEducationData({
            school: "",
            degree: "",
            fieldOfStudy: "",
            startMonth: "",
            startYear: "",
            location: "",
            grade: "",
            activities: "",
            description: "",
            educationId: "",
            endMonth: "",
            endYear: "",
            isCurrentlyWorking: false,
        });
        setQuery("");
        setDegreeQuery("");
        setFieldQuery("");
        setShowModal(true);
        setIsEditMode(false);
        setLocationquery("");
        setSuggestions([]);
        setDegreeSuggestions([]);
        setFieldSuggestions([]);
        setLocationSuggestions([]);

    }
    const addLanguage = () => {

        setLanguageData({
            language: '',
            languageProficiency: '',
            proficiency: '',
            testName: '',
            totalScore: '',
            readingScore: '',
            listeningScore: '',
            writingScore: '',
            speakingScore: '',
            completion_year: '',
            completion_month: '',
            scoreCard: '',
            score_card_url: '',
            employee_language_id: ''
        });
        setLanguageQuery('');
        setShowLanguageModal(true);
        setIsEditModeLm(false);
        setLanguageSuggestions([]);
        setFile(null); // Ensure no leftover file
        setFileName('');
        setFileSize('');
        setImagePreview(null); // Clear any existing preview


    }

    // Fetch Languages for Autocomplete
    const fetchLanguages = async (search) => {
        if (!search) {
            setLanguageSuggestions([]);
            return;
        }
        setLoading(true);
        try {
            const response = await API.get(`/autocomplete/language?query=${search}`);
            setLanguageSuggestions(response);
        } catch (error) {
            setLanguageSuggestions([]);
        } finally {
            setLoading(false);
        }
    };
    const handleSubmitLanguage = async (values, { setSubmitting, resetForm }) => {
        setLoading(true);
        try {
            let fileUrl = null;

            // ✅ Upload file first (if selected)
            if (file) {
                const formData = new FormData();
                formData.append("file", file);

                const uploadResponse = await fetch("https://api.jobcritics.com/upload", {
                    method: "POST",
                    body: formData,
                });

                const uploadResult = await uploadResponse.json();
                if (!uploadResponse.ok) throw new Error(uploadResult.message);
                fileUrl = uploadResult.fileUrl; // Get uploaded file URL
            }

            console.log(values);
            const payload = values.employee_language_id != null && values.employee_language_id > 0 ? {
                language_name: values.language,
                proficiency_level_id: values.languageProficiency === "no" ? values.proficiency : null,
                test_name: values.languageProficiency === "yes" ? values.testName : null,
                total_score: values.languageProficiency === "yes" ? values.totalScore : null,
                reading: values.languageProficiency === "yes" ? values.readingScore : null,
                listening: values.languageProficiency === "yes" ? values.listeningScore : null,
                writing: values.languageProficiency === "yes" ? values.writingScore : null,
                speaking: values.languageProficiency === "yes" ? values.speakingScore : null,
                completion_month: values.languageProficiency === "yes" ? values.completion_month : null,
                completion_year: values.languageProficiency === "yes" ? values.completion_year : null,
                score_card_url: fileUrl,
                has_certificate: values.languageProficiency === "yes" ? 1 : 0,
                employee_language_id: values.employee_language_id
            } : {
                language_name: values.language,
                proficiency_level_id: values.languageProficiency === "no" ? values.proficiency : null,
                test_name: values.languageProficiency === "yes" ? values.testName : null,
                total_score: values.languageProficiency === "yes" ? values.totalScore : null,
                reading: values.languageProficiency === "yes" ? values.readingScore : null,
                listening: values.languageProficiency === "yes" ? values.listeningScore : null,
                writing: values.languageProficiency === "yes" ? values.writingScore : null,
                speaking: values.languageProficiency === "yes" ? values.speakingScore : null,
                completion_month: values.languageProficiency === "yes" ? values.completion_month : null,
                completion_year: values.languageProficiency === "yes" ? values.completion_year : null,
                score_card_url: fileUrl,
                has_certificate: values.languageProficiency === "yes" ? 1 : 0,
            };
            console.log(payload);
            if (values.employee_language_id != null && values.employee_language_id > 0) {
                const response = await API.put(`/language/${values.employee_language_id}`, payload);
                console.log("Language Added:", response);
            } else {
                const response = await API.post(`/language`, payload);
                console.log("Language Added:", response);

            }

            fetchLanguage();
            setShowLanguageModal(false);
            resetForm();
            setPopupData({
                isOpen: true,
                title: "Success!",
                message: "Your details have been updated successfully.",
                onConfirm: () => {
                    console.log("Popup Confirmed! Reloading Page...");
                    window.location.reload(); // Direct reload without state change
                },
                onClose: () => setPopupData({ isOpen: false }),
            });
        } catch (error) {
            console.error("Error adding language:", error);
        } finally {
            setLoading(false);
            setSubmitting(false);
        }
    };
    // const handleFileChange = (event, setFieldValue) => {
    //     const uploadedFile = event.target.files[0];
    //     if (!uploadedFile) return;

    //     const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
    //     if (!allowedTypes.includes(uploadedFile.type)) {
    //         alert("Only PNG, JPG, and PDF files are allowed.");
    //         return;
    //     }

    //     setFile(uploadedFile);
    //     setFileName(uploadedFile.name);
    //     setFileSize((uploadedFile.size / 1024).toFixed(1) + " kb");
    //     // Update Formik field value for scoreCard
    //     setFieldValue("scoreCard", uploadedFile);
    // };
    const handleFileChange = (event, setFieldValue) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
            setFileSize(`${(selectedFile.size / 1024).toFixed(2)} KB`);
            setFieldValue("scoreCard", selectedFile);
            setFieldValue("score_card_url", ""); // Clear existing URL
            // Remove FileReader to prevent auto-preview
            // const reader = new FileReader();
            // reader.onload = (e) => setImagePreview(e.target.result);
            // reader.readAsDataURL(selectedFile);
        }
    };

    const handleRemoveFile = (setFieldValue) => {
        setFile(null);
        setFileName("");
        setFileSize("");
        setFieldValue("scoreCard", "");
        setFieldValue("score_card_url", "");
        setImagePreview(null);
        //setLanguageData({ ...languageData,scoreCard: "",  score_card_url: "" });
    };
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const editEducation = (education) => {
        setEducationData({
            school: education.school_name,
            degree: education.degree_name,
            fieldOfStudy: education.field_name,
            location: education.city_id,
            startMonth: education.start_month,
            startYear: education.start_year,
            grade: education.grade,
            endMonth: education.end_month,
            endYear: education.end_year,
            isCurrentlyWorking: education.is_current == 1 ? true : false,
            activities: education.activities,
            description: '',
            educationId: education.education_id,
        });
        setQuery(education.school_name);
        setDegreeQuery(education.degree_name);
        setFieldQuery(education.field_name);
        setLocationquery(education.location);
        setShowModal(true);
        setIsEditMode(true);
        setSuggestions([]);
        setDegreeSuggestions([]);
        setFieldSuggestions([]);
        setLocationSuggestions([]);
    };

    const [modalHeight, setModalHeight] = useState("auto");

    useEffect(() => {
        if (languageSuggestions.length > 0) {
            setModalHeight("350px");
        } else {
            setModalHeight("auto");
        }
    }, [languageSuggestions]);
    const editLanguage = (languageVal) => {
        const proficiency = languageVal.has_certificate === 0 ? "no" : "yes";
        setShowLanguageModal(true);
        setLanguageData({
            language: languageVal.language_name,
            languageProficiency: languageVal.has_certificate == 0 ? 'no' : 'yes',
            proficiency: languageVal.proficiency_level_id,
            testName: languageVal.test_name,
            totalScore: languageVal.total_score,
            readingScore: languageVal.reading_score,
            listeningScore: languageVal.listening_score,
            writingScore: languageVal.writing_score,
            speakingScore: languageVal.speaking_score,
            completion_month: languageVal.completion_month,
            completion_year: languageVal.completion_year,
            scoreCard: "", // ✅ New file will be selected
            score_card_url: languageVal.score_card_url || null,
            employee_language_id: languageVal.employee_language_id
        });
        setLanguageQuery(languageVal.language_name);
        setIsEditModeLm(true);
        setLanguageSuggestions([]);
        setLanguageProficiency(proficiency);

        if (languageVal.score_card_url) {
            setFileName(languageVal.score_card_url.split("/").pop() || "Existing Score Card");

            // ✅ Fetch file size
            fetch(`${DOMAIN_URL}/${languageVal.score_card_url}`, { method: "HEAD" })
                .then((response) => {
                    const size = response.headers.get("content-length");
                    if (size) {
                        setFileSize(`${(size / 1024).toFixed(2)} KB`);
                    } else {
                        setFileSize("");
                    }
                })
                .catch(() => setFileSize(""));
        } else {
            setFileName("");
            setFileSize("");
            setImagePreview(null);
        }
    };;
    const [visibleEduSkills, setVisibleEduSkills] = useState(10);
    const loadMoreEduSkills = () => {
        setVisibleEduSkills((prev) => prev + 10);
    };
    const handleDelete = async (values) => {
        setLoading(true); // Loader start
        try {
            const response = await API.delete(`/education/${values.educationId}`);
            fetchEducation();
            setShowPopup(false);
        } catch (error) {
            console.error("Error deleting:", error);
        }

        setLoading(false); // Loader stop
    };
    const handleDeleteL = async (values) => {
        setLoading(true); // Loader start
        try {
            const response = await API.delete(`/language/${values.employee_language_id}`);
            fetchLanguage();
            setShowPopupL(false);
        } catch (error) {
            console.error("Error deleting:", error);
        }

        setLoading(false); // Loader stop
    };
    const fetchLocation = async (search) => {
        if (!search) {
            setLocationSuggestions([]);
            return;
        }
        setLoading(true);
        try {
            const response = await API.get(`/autocomplete/locations?query=${search}`);
            console.log("Raw API Response:", response); // Debugging: Check API response

            // Extract the array properly from response
            const locationList = Array.isArray(response) ? response : [];

            console.log("Extracted locationList List:", locationList); // Debugging: Check extracted list

            setLocationSuggestions(locationList); // Update state with extracted list
        } catch (error) {
            console.error("Error fetching locationList:", error);
            setLocationSuggestions([]);
        } finally {
            setLoading(false);
        }
    };
    const handleCloseWithConfirmation = (values) => {

        const currentValues = formikRef.current?.values || {};
        const isFormFilled = Object.values(currentValues).some(val => val && val !== "");
        if (!isEditMode && isFormFilled) {
            setShowPopupC(true); // Show confirmation popup
        } else {
            setShowModal(false)
            setTimeout(() => {
                formikRef.current?.resetForm(); // Reset form fields
            }, 100);
        }
    };
    const handleCloseWithConfirmation2 = () => {
        // Get form values
        const currentValues = formikRef.current?.values || {};

        // Check if we are in "Add Mode" and any field is filled
        const isFormFilled = Object.values(currentValues).some(val => val && val !== "");

        if (!isEditModeLm && isFormFilled) {
            setShowPopupCe(true); // Show confirmation popup
        } else {
            setShowLanguageModal(false);
            setLanguageProficiency(""); // Reset state when closing
            setTimeout(() => {
                formikRef.current?.resetForm(); // Reset form fields
            }, 100);
        }
    };
    const handleCreateLink = () => {
        setShowModalE(true);
    };

    return (
        <>
            {
                academicRatings?.total_reviews > 0 ? (<section className="pt-[20px] pb-[20px] px-4">

                    <Container >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between items-baseline">
                                    <div>
                                        <h3 className="text-[28px] font-bold text-black mb-1 leading-[1.5]">Academic Reviews</h3>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="rounded-lg p-6 pt-0 border-1 custm-bg shdo-box" >
                            <h3 className="text-[24px] font-bold mb-0 text-[#000]">Summary</h3>
                            <hr className="border-gray-300 mb-4 1" />
                            <Col lg={6} md={12} className="mb-6">
                                <div className="flex items-start space-x-3 mb-3">
                                    <div className="mt-[8px]">
                                        <span className="text-[40px] font-bold text-gray-900 lt">{academicRatings?.overall_rating.toFixed(1) ?? "0.0"}</span>
                                    </div>
                                    <div className="leading-[1.4]">
                                        <span className="text-[16px] font-bold text-gray-900">{getRatingLabel(academicRatings?.overall_rating)}</span>
                                        <div className="flex items-center mb-2">
                                            {/* {[...Array(5)].map((_, index) => (
                                                <i
                                                    key={index}
                                                    className={`fas fa-star text-[16px] ${index < Math.round(academicRatings?.overall_rating ?? 0)
                                                        ? "yellow-i"
                                                        : "text-gray-300"
                                                        }`}
                                                ></i>
                                            ))} */}
                                            <StarRating rating={academicRatings?.overall_rating} starSize={15} />
                                            <span className="text-gray-600 text-[14px] ml-2 cursor-pointer hover:underline hover:text-black transition" onClick={scrollToReviews}>
                                                {academicRatings?.total_reviews ?? 0} Reviews
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-[#74787F] text-white text-[14px] px-3 py-0 rounded-[0.6rem] inline-block shdo-box">
                                    This summary is created by AI, based on recent reviews.
                                </div>
                                <p className="text-gray-700 text-[14px] mt-3 leading-[1.5]">
                                    <TextWithReadMore text={academic?.ai_summary} />
                                </p>

                                {/* Key Strengths */}
                                {academic.key_strengths != null && Array.isArray(academic.key_strengths) && academic.key_strengths.length > 0 ? (
                                    <div className="mt-2">
                                        <h4 className="font-bold text-gray-900 text-[16px] mb-2">Key Strengths</h4>
                                        <div className="flex flex-wrap gap-2">
                                            {
                                                academic.key_strengths.map((skill, index) => (
                                                    <span key={index} className="flex items-center px-2 py-0 text-sm font-medium text-black rounded-full gap-2 h-m bg-white">
                                                        <i className={`fas fa-star ${getStarColorClass(skill.rating)}`}></i>
                                                        <span className="black-class"> {skill.name} </span>
                                                        <span className="text-gray-500"> ({skill.total})</span>
                                                    </span>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {/* Improvement Areas */}
                                {academic?.improvement_areas && Array.isArray(academic.improvement_areas) && academic.improvement_areas.length > 0 ? (
                                    <div className="mt-2">
                                        <h4 className="font-bold text-gray-900 text-[16px] mb-2">Improvement Areas</h4>
                                        <div className="flex flex-wrap gap-2">
                                            {
                                                academic.improvement_areas.map((weakness, index) => (
                                                    <span key={index} className="flex items-center px-2 py-0 font-medium text-black text-sm rounded-full gap-2 h-m bg-white">
                                                        <i className={`fas fa-star ${getStarColorClass(weakness.rating)}`}></i>
                                                        <span className="black-class"> {weakness.name} </span>
                                                        <span className="text-gray-500"> ({weakness.total})</span>
                                                    </span>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </Col>

                            <Col lg={6} md={12} className="">
                                <Accordions data={Array.isArray(academic?.category_ratings) ? academic.category_ratings : []} />
                            </Col>
                        </Row>
                    </Container>
                </section>) : (<></>)
            }

            <section className={` px-4 ${education.length === 0 ? "pb-0" : "pb-0"}`}>
                <Container>
                    {/* Experience Header */}
                    <Row>
                        <Col xs={12} md={12} className={`${education.length === 0 ? "p-0" : ""}`}>
                            <div className={`flex justify-between items-baseline ${education.length === 0 ? "bg-white rounded-lg p-6 border border-gray-200  shadow-lg" : ""}`}>
                                <div>
                                    <h3 className={`text-[22px] font-bold text-gray-900 mb-2 leading-[1.4] ${education.length === 0 ? "mt-1" : "mt-3"}`}>Education <InfoTooltip text="Add education details to get feedback from school friends and teachers." /></h3>
                                </div>
                                <div className="cl-plu">
                                    <i className="fas fa-plus black-class font-thin text-[27px] cursor-pointer i-p" onClick={addSchool}></i>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {
                        education.map((educationVal, index) => (
                            <Row className="bg-white rounded-lg p-6 border-1 mb-[20px] !pr-[14px] ">
                                <Col lg={1} md={2} sm={2}>
                                    <img
                                        src={IMAGE_PATHS.SCHOOL}
                                        className="w-[60px] h-[60px] bg-gray-300 rounded-md"
                                    />
                                </Col>
                                <Col lg={10} md={9} sm={9} className="leading-[1.4]">
                                    <h4 className="text-lg font-bold text-gray-900 mb-0 truncate max-w-[240px]">{educationVal.school_name}</h4>
                                    <p className="text-gray-700">{educationVal.degree_name}</p>
                                    <p className="text-gray-700 ">{educationVal.start_year} {educationVal.end_year != null ? '-' + educationVal.end_year : ''}</p>
                                    <p className="text-gray-700">Grade: {educationVal.grade}</p>
                                    <p className="text-gray-700 mt-2">
                                        <TextWithReadMore text={educationVal.activities} />
                                    </p>

                                </Col>

                                <Col lg={1} md={1} sm={1} className="pr-0 flex justify-end">
                                    <div className="cl-plu">
                                        <i className="fas fa-pencil-alt black-class font-bold text-[15px] cursor-pointer i-p !top-[5px]" onClick={() => editEducation(educationVal)}></i>
                                    </div>
                                </Col>
                                {educationVal.overall_rating != null && educationVal.overall_rating.total_reviews > 0 ? (<>
                                    <hr className="border-gray-300 my-4 border-t-1" />
                                    <Col lg={2} md={12} className="text-start">
                                        <h1 className="text-[40px] font-bold text-gray-900 mb-0 leading-[1] lt">{educationVal.overall_rating.overall_rating}</h1>
                                        <p className="text-gray-600 font-semibold">{getRatingLabel(educationVal.overall_rating.overall_rating)}</p>
                                        <div className="yellow-i text-lg">
                                            {/* {[...Array(5)].map((_, index) => (
                                                <i key={index} className={`fas fa-star ${index < Math.round(educationVal.overall_rating.overall_rating ?? 0)
                                                    ? "yellow-i"
                                                    : "text-gray-300"
                                                    }`}></i>
                                            ))} */}
                                            <StarRating rating={educationVal.overall_rating.overall_rating} starSize={15} />
                                        </div>
                                        <p className="text-gray-500 text-sm cursor-pointer hover:underline hover:text-black transition" onClick={scrollToReviews}>{educationVal.overall_rating.total_reviews} Reviews</p>
                                    </Col>

                                    {/* Rating Breakdown */}
                                    <Col lg={4} md={12} sm={12} className="border-r border-gray-300">
                                        <div className="space-y-1">
                                            {[
                                                { label: "Excellent", value: parseInt(educationVal.overall_rating.excellent) },
                                                { label: "Good", value: parseInt(educationVal.overall_rating.very_good) },
                                                { label: "Average", value: parseInt(educationVal.overall_rating.average) },
                                                { label: "Poor", value: parseInt(educationVal.overall_rating.poor) },
                                                { label: "Terrible", value: parseInt(educationVal.overall_rating.terrible) },
                                            ].map((item, index) => (
                                                <div key={index} className="flex items-center w-full">
                                                    {/* Label */}
                                                    <span className="text-gray-900 text-sm font-medium w-[25%]">{item.label}</span>

                                                    {/* Progress Bar */}
                                                    <div className="w-[55%] h-[12px] bg-gray-200 rounded-full overflow-hidden mx-2">
                                                        <div
                                                            className="h-full yellow-b rounded-full"
                                                            style={{
                                                                width: `${educationVal.overall_rating.total_reviews > 0
                                                                        ? (item.value / educationVal.overall_rating.total_reviews) * 100
                                                                        : 0
                                                                    }%`,
                                                            }}
                                                        ></div>
                                                    </div>

                                                    {/* Count */}
                                                    <span className="text-gray-900 text-sm font-medium w-[15%] text-start">
                                                        {item.value}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} className="pl-[45px]">
                                        <div className="grid grid-cols-2 sm:grid-cols-2 gap-y-2 gap-x-4">
                                            {educationVal.categories.map((item, index) => (
                                                <div key={index} className="flex flex-col justify-center items-start leading-[1]">
                                                    <div className="leading-[1.4] flex items-center h-[20px]">
                                                        <span className="font-medium text-[14px] text-[#000]">{item.name}</span>
                                                    </div>
                                                    <div className="flex items-center w-full">
                                                        <div className="w-[78%] h-[12px] bg-gray-200 rounded-full overflow-hidden mr-2">
                                                            <div className="h-full yellow-b" style={{ width: `${(item.avg_rating / 5) * 100}%` }}></div>
                                                        </div>
                                                        <div>
                                                            <span className="text-[#000] text-[14px]">{item.avg_rating.toFixed(1)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Col>


                                    {/* Tags Section */}
                                    <Row className="mt-4">
                                        <Col lg={12}>
                                            <div className="flex flex-wrap gap-2 mt-2">
                                                {
                                                    educationVal.skills.slice(0, visibleEduSkills).map((skill, index) => {
                                                        let starColor = getStarColorClass(skill.avg_rating);
                                                        return (
                                                            <span
                                                                key={index}
                                                                className="flex items-center px-2 py-0 font-medium text-black text-sm rounded-full gap-2 h-m bg-white"
                                                            >
                                                                <i className={`fas fa-star mr-1 ${starColor}`}></i>
                                                                <span className="black-class">{skill.name}</span>
                                                                <span className="text-gray-500">({skill.total_ratings})</span>
                                                            </span>
                                                        );
                                                    })
                                                }
                                                {visibleEduSkills < educationVal.skills.length && (
                                                    <button
                                                        onClick={loadMoreEduSkills}
                                                        className="text-black font-bold text-sm cursor-pointer hover:bg-gray-200 px-3 py-1 rounded"
                                                    >
                                                        Load More
                                                    </button>
                                                )}
                                            </div>


                                        </Col>
                                    </Row>

                                    {/* CTA Button */}
                                    <Row className="mt-6 pr-[7px]">
                                        <Col lg={6} >
                                            <button className="btn-explore-white" onClick={scrollToReviews}>
                                                <span className="mr-2">Jump to reviews</span>
                                                <i className="fas fa-arrow-down"></i>
                                            </button>
                                        </Col>
                                        <Col lg={6} className="flex justify-end !pr-[0px]">
                                            <button
                                                className="btn-explore-white"
                                                onClick={() => setShowModalE(true)}
                                            >
                                                <span className="mr-2">Get more ratings</span>
                                            </button>
                                            <ShareLinkModal
                                                show={showModalE}
                                                onClose={() => setShowModalE(false)}
                                                onCreateLink={handleCreateLink}
                                            />
                                        </Col>
                                    </Row>
                                </>) : (<>
                                    <Row className="mt-6 pr-[7px]">
                                        <Col lg={6} >

                                        </Col>
                                        <Col lg={6} className="flex justify-end !pr-[0px]">
                                            <button
                                                className="btn-explore-white"
                                                onClick={() => setShowModalE(true)}
                                            >
                                                <span className="mr-2">Get more ratings</span>
                                            </button>
                                            <ShareLinkModal
                                                show={showModalE}
                                                onClose={() => setShowModalE(false)}
                                                onCreateLink={handleCreateLink}
                                            />
                                        </Col>
                                    </Row>
                                </>)
                                }
                            </Row>
                        ))
                    }

                </Container>
            </section>
            <section className="py-3 px-4 !pt-[20px] !pb-[40px]">
                <Container>
                    <Row>
                        <Col xs={12} md={12} className={`${language.length === 0 ? "p-0" : ""}`}>
                            <div className={`flex justify-between items-baseline  ${language.length === 0 ? "bg-white rounded-lg p-6 border border-gray-200  shadow-lg" : ""}`}>
                                <div>
                                    <h3 className={`text-[22px] font-bold text-gray-900 mb-2 leading-[1.4]  ${language.length === 0 ? "mt-1" : "mt-3"}`}>Language <InfoTooltip text="Add your proficiency and upload certifications for a stronger profile." /></h3>
                                </div>
                                <div className="cl-plu">
                                    <i
                                        className="fas fa-plus black-class font-thin text-[27px] cursor-pointer i-p"
                                        onClick={() => addLanguage()}
                                    ></i>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {
                        language.map((languageVal, index) => (

                            languageVal.has_certificate === 0 ? (
                                <Row className="bg-white rounded-lg p-6 border mb-4 !pr-[14px]">
                                    <Col lg={10} md={4} className="mb-2">
                                        <h4 className="text-lg font-bold text-gray-900 mb-0">{languageVal.language_name}</h4>
                                        <p className="text-gray-500 text-sm font-semibold flex items-center">
                                            <span className="mr-2">{languageVal.level_name}</span>
                                        </p>
                                    </Col>
                                    <Col lg={2} md={1} className="pr-0 flex justify-end">
                                        <div className="cl-plu"><i className="fas fa-pencil-alt black-class font-bold text-[15px] i-p !top-[5px] cursor-pointer" onClick={() => editLanguage(languageVal)}></i></div>
                                    </Col>
                                </Row>
                            ) : (
                                <Row className="bg-white rounded-lg p-6 border mb-4 !pr-[14px]">
                                    <Col lg={10} md={4} className="mb-2">
                                        <h4 className="text-lg font-bold text-gray-900 mb-0">{languageVal.language_name}</h4>
                                        <p className="font-bold text-gray-900 text-sm">{languageVal.test_name}</p>
                                        <p className="text-gray-500 text-sm font-semibold flex items-center">
                                            <span className="mr-2">{months[languageVal.completion_month]} {languageVal.completion_year}</span>
                                            <span className="px-2 py-[2px] text-xs font-medium bg-black text-white rounded-full flex items-center">
                                                <i className="fas fa-check-circle yellow-class mr-1"></i>
                                                Certified
                                            </span>
                                        </p>
                                    </Col>
                                    <Col lg={2} md={1} className="pr-0 flex justify-end">
                                        <div className="cl-plu"><i className="fas fa-pencil-alt black-class font-bold text-[15px] i-p !top-[5px] cursor-pointer" onClick={() => editLanguage(languageVal)}></i></div>
                                    </Col>
                                    <hr className="border-gray-300 w-full my-3" />

                                    <Col lg={2} md={12} className="text-start">
                                        <div className="box-total">
                                            <h4 className="text-lg font-bold yellow-class mb-0 text-center">Total</h4>
                                            <h1 className="text-[40px] font-bold yellow-class leading-[1] mb-0">{Math.round(languageVal.total_score)}</h1>
                                        </div>

                                    </Col>
                                    <Col lg={9} md={12} sm={12} className="flex flex-col justify-center">
                                        <div className="grid grid-cols-2 md:grid-cols-2 gap-y-2 gap-x-4 ">
                                            {[
                                                { label: "Reading", score: Math.round(languageVal.reading_score) },
                                                { label: "Writing", score: Math.round(languageVal.writing_score) },
                                                { label: "Listening", score: Math.round(languageVal.listening_score) },
                                                { label: "Speaking", score: Math.round(languageVal.speaking_score) },
                                            ].map((item, index) => (
                                                <div key={index} className="flex items-center justify-between ">
                                                    {/* Label */}
                                                    <span className="text-gray-900 text-sm font-medium w-[25%]">{item.label}</span>

                                                    {/* Progress Bar */}
                                                    <div className="w-[75%] h-[12px] bg-gray-200 rounded-full overflow-hidden">
                                                        <div className="h-full yellow-b rounded-full" style={{ width: `${item.score}%` }}></div>
                                                    </div>

                                                    {/* Score Value */}
                                                    <span className="text-gray-900 text-sm font-medium w-[15%] text-center ">{item.score}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col lg={1} md={12} className="text-end flex items-start justify-end pr-[3px] ">
                                        <a onClick={() => handlePreview(languageVal.score_card_url)}>
                                            <i className="fas fa-file-pdf font-bold text-[15px] text-black cursor-pointer"></i>
                                        </a>
                                    </Col>
                                    {imagePreview && <ImagePopup imageUrl={imagePreview} onClose={() => setImagePreview(null)} />}

                                </Row>)


                        ))
                    }

                </Container>
            </section>

            <Modal show={showModal} onHide={() => handleCloseWithConfirmation(educationData)} centered backdrop="static" keyboard={false} className="zl">
                <Modal.Header closeButton className="p-4 hed">
                    <Modal.Title className="text-lg font-bold text-gray-900"> {isEditMode ? "Edit Education" : "Add Education"}</Modal.Title>
                </Modal.Header>


                <Formik
                    enableReinitialize
                    initialValues={educationData}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    innerRef={formikRef}
                >
                    {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
                        <Form onSubmit={handleSubmit} >
                            <div className="p-[28px] relative pb-0 m-h">
                                {/* School */}
                                {/* Degree Autocomplete */}
                                <Form.Group className="mb-3 position-relative leading-[1.5]">
                                    <Form.Label className="mb-0 text-g">Degree/Certification *</Form.Label>
                                    <Field
                                        type="text"
                                        name="degree"
                                        className="form-control fomc-cls"
                                        placeholder="Search Degree..."
                                        value={degreeQuery}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setDegreeQuery(value);
                                            setFieldValue("degree", value);
                                            fetchDegrees(value);
                                        }}
                                        autoComplete="off"
                                        onBlur={(e) => {
                                            // Delay hiding to allow onClick to process
                                            setTimeout(() => {
                                                setDegreeSuggestions([]);
                                            }, 200); // Small delay to let onClick fire first
                                        }}
                                    />
                                    <ErrorMessage name="degree" component="div" className="text-danger text-sm" />
                                    {degreeSuggestions.length > 0 && (
                                        <ul className="list-group position-absolute w-100 mt-1 bg-white shadow-lg rounded z-50">
                                            {degreeSuggestions.map((degree, index) => (
                                                <li
                                                    key={index}
                                                    className="list-group-item list-group-item-action"
                                                    onClick={() => {
                                                        setDegreeQuery(degree.degree_name);
                                                        setFieldValue("degree", degree.degree_name);
                                                        setDegreeSuggestions([]);
                                                    }}
                                                >
                                                    {degree.degree_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </Form.Group>

                                {/* Field of Study Autocomplete */}
                                <Form.Group className="mb-3 position-relative leading-[1.5]">
                                    <Form.Label className="mb-0 text-g">Field of Study/Major *</Form.Label>
                                    <Field
                                        type="text"
                                        name="fieldOfStudy"
                                        className="form-control fomc-cls"
                                        placeholder="Search Field of Study..."
                                        value={fieldQuery}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setFieldQuery(value);
                                            setFieldValue("fieldOfStudy", value);
                                            fetchFieldsOfStudy(value);
                                        }}
                                        onBlur={(e) => {
                                            // Delay hiding to allow onClick to process
                                            setTimeout(() => {
                                                setFieldSuggestions([]);
                                            }, 200); // Small delay to let onClick fire first
                                        }}
                                        autoComplete="off"
                                    />
                                    <ErrorMessage name="fieldOfStudy" component="div" className="text-danger text-sm" />
                                    {fieldSuggestions.length > 0 && (
                                        <ul className="list-group position-absolute w-100 mt-1 bg-white shadow-lg rounded z-50">
                                            {fieldSuggestions.map((field, index) => (
                                                <li
                                                    key={index}
                                                    className="list-group-item list-group-item-action"
                                                    onClick={() => {
                                                        setFieldQuery(field.field_name);
                                                        setFieldValue("fieldOfStudy", field.field_name);
                                                        setFieldSuggestions([]);
                                                    }}
                                                >
                                                    {field.field_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </Form.Group>

                                <Form.Group className="mb-3 position-relative leading-[1.5]">
                                    <Form.Label className="mb-0 text-g">University/Institution Name *</Form.Label>
                                    <Field
                                        type="text"
                                        name="school"
                                        className="form-control fomc-cls"
                                        placeholder="Search University/Institution..."
                                        value={query}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setQuery(value);
                                            setFieldValue("school", value);

                                            if (value.length > 1) {
                                                fetchSchool(value);
                                            } else {
                                                setSuggestions([]);
                                            }
                                        }}
                                        onBlur={(e) => {
                                            // Delay hiding to allow onClick to process
                                            setTimeout(() => {
                                                setSuggestions([]);
                                            }, 200); // Small delay to let onClick fire first
                                        }}
                                        autoComplete="off"
                                    />
                                    <ErrorMessage name="school" component="div" className="text-danger text-sm" />

                                    {suggestions.length > 0 && (
                                        <ul className="list-group position-absolute w-100 mt-1 bg-white shadow-lg rounded z-50">
                                            {suggestions.map((school, index) => (
                                                <li
                                                    key={index}
                                                    className="list-group-item list-group-item-action"
                                                    onClick={() => {
                                                        setQuery(school.school_name);
                                                        setFieldValue("school", school.school_name);
                                                        setSuggestions([]);
                                                    }}
                                                >
                                                    {school.school_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </Form.Group>
                                {/* Location */}
                                <Form.Group className="mb-3 position-relative">
                                    <Form.Label className="mb-0 text-g">Location (City, Country) *</Form.Label>
                                    <Field
                                        type="text"
                                        name="location"
                                        className="form-control fomc-cls"
                                        placeholder="Location..."
                                        value={locationquery}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setLocationquery(value);
                                            //setFieldValue("location", value); // Updates Formik field value

                                            if (value.length > 1) {
                                                fetchLocation(value); // Call API only after 2+ chars
                                            } else {
                                                setFieldValue("location", '');
                                                setLocationSuggestions([]); // Reset dropdown when input is empty
                                            }
                                        }}
                                        onBlur={(e) => {
                                            // Delay hiding to allow onClick to process
                                            setTimeout(() => {
                                                setLocationSuggestions([]);
                                            }, 200); // Small delay to let onClick fire first
                                        }}
                                        autoComplete="off"
                                    />
                                    <ErrorMessage name="location" component="div" className="text-danger text-sm" />

                                    {locationsuggestions.length > 0 && (
                                        <ul className="list-group position-absolute w-100 mt-1 bg-white shadow-lg rounded z-50">
                                            {locationsuggestions.map((locationVal, index) => (
                                                <li
                                                    key={locationVal.city_id} // Unique key based on ID
                                                    className="list-group-item list-group-item-action"
                                                    onClick={() => {
                                                        setLocationquery(locationVal.city_name + `, ` + locationVal.state_name + `, ` + locationVal.country_name);
                                                        setFieldValue("location", locationVal.city_id);
                                                        setLocationSuggestions([]); // Hide dropdown after selection
                                                    }}
                                                >
                                                    {locationVal.city_name + `, ` + locationVal.state_name + `, ` + locationVal.country_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}



                                </Form.Group>

                                {/* Currently Working Checkbox */}
                                <Form.Group className="mb-3 leading-[1.5]">
                                    <Field
                                        className="check-cl fomc-cls"
                                        type="checkbox"
                                        name="isCurrentlyWorking"
                                        checked={values.isCurrentlyWorking}
                                        onChange={() => {
                                            setFieldValue("isCurrentlyWorking", !values.isCurrentlyWorking);
                                            if (!values.isCurrentlyWorking) {
                                                setFieldValue("endMonth", "");
                                                setFieldValue("endYear", "");
                                            }
                                        }}
                                    />
                                    <Form.Label className="ml-2">I am currently studying</Form.Label>
                                </Form.Group>

                                {/* Start & End Dates */}
                                <Row className="mb-3 leading-[1.5]">
                                    <Col>
                                        <Form.Label className="mb-0 text-g">Start Month *</Form.Label>
                                        <Field as="select" name="startMonth" className="form-select fomc-cls">
                                            <option value="">Month</option>
                                            <option value="1">January</option>
                                            <option value="2">February</option>
                                            <option value="3">March</option>
                                            <option value="4">April</option>
                                            <option value="5">May</option>
                                            <option value="6">June</option>
                                            <option value="7">July</option>
                                            <option value="8">August</option>
                                            <option value="9">September</option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                        </Field>
                                        <ErrorMessage name="startMonth" component="div" className="text-danger text-sm" />
                                    </Col>
                                    <Col>
                                        <Form.Label className="mb-0 text-g">Start Year *</Form.Label>
                                        <Field as="select" name="startYear" className="form-select fomc-cls">
                                            <option value="">Year</option>
                                            {Array.from({ length: 100 }, (_, index) => {
                                                const year = new Date().getFullYear() - index; // Get last 20 years
                                                return <option key={year} value={year}>{year}</option>;
                                            })}
                                        </Field>
                                        <ErrorMessage name="startYear" component="div" className="text-danger text-sm" />
                                    </Col>
                                </Row>

                                <Row className="mb-3 leading-[1.5]">
                                    <Col>
                                        <Form.Label className="mb-0 text-g">End Month *</Form.Label>
                                        <Field as="select" name="endMonth" className="form-select fomc-cls" disabled={values.isCurrentlyWorking}>

                                            <option value="">Month</option>
                                            <option value="1">January</option>
                                            <option value="2">February</option>
                                            <option value="3">March</option>
                                            <option value="4">April</option>
                                            <option value="5">May</option>
                                            <option value="6">June</option>
                                            <option value="7">July</option>
                                            <option value="8">August</option>
                                            <option value="9">September</option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                        </Field>
                                        <ErrorMessage name="endMonth" component="div" className="text-danger text-sm" />
                                    </Col>
                                    <Col>
                                        <Form.Label className="mb-0 text-g">End Year *</Form.Label>

                                        <Field as="select" name="endYear" className="form-select fomc-cls" disabled={values.isCurrentlyWorking}>
                                            <option value="">Year</option>
                                            {Array.from({ length: 100 }, (_, index) => {
                                                const year = new Date().getFullYear() - index; // Get last 20 years
                                                return <option key={year} value={year}>{year}</option>;
                                            })}
                                        </Field>
                                        <ErrorMessage name="endYear" component="div" className="text-danger text-sm" />
                                    </Col>
                                </Row>


                                {/* Grade */}
                                <Form.Group className="mb-3 leading-[1.5]">
                                    <Form.Label className="mb-0 text-g">GPA/Percentage (Optional)</Form.Label>
                                    <Field type="text" name="grade" className="form-control fomc-cls" placeholder="Grade..." />
                                    <ErrorMessage name="grade" component="div" className="text-danger text-sm" />
                                </Form.Group>

                                {/* Activities & Description */}
                                <Form.Group className="mb-[100px] leading-[1.5]">
                                    <Form.Label className="mb-0 text-g">Any other details (Optional)</Form.Label>
                                    <Field
                                        as="textarea"
                                        name="activities"
                                        className="form-control fomc-cls"
                                        rows={3}
                                        style={{ resize: "none" }}
                                        placeholder="Activities and Societies..."
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const words = value.trim().split(/\s+/).filter(word => word !== "");
                                            setFieldValue("activities", value); // Always set the value
                                            setActivityCount(words.length); // Update word count
                                        }}
                                    />
                                    <ErrorMessage
                                        name="activities"
                                        component="div"
                                        className="text-danger text-sm"
                                    />
                                    <div className="text-end text-muted">
                                        {activityCount}/500
                                    </div>
                                </Form.Group>

                                {/* <Form.Group className="mb-[100px] leading-[1.5]">
                                    <Form.Label className="mb-0 text-g">Description</Form.Label>
                                    <Field
                                        as="textarea"
                                        name="description"
                                        className="form-control fomc-cls"
                                        rows={3}
                                        placeholder="Description..."
                                        maxLength={500}
                                        onChange={(e) => {
                                            setFieldValue("description", e.target.value);
                                            setDescriptionCount(e.target.value.length);
                                        }}
                                    />
                                    <div className="text-end text-muted">{descriptionCount}/500</div>
                                </Form.Group> */}
                            </div>
                            {/* Save Button */}
                            <div className={`absolute bottom-0 left-0 w-full bg-white p-4 border-t border-gray-300 flex  rounded-b-lg ${isEditMode ? "justify-between" : "justify-end"}`}>
                                {isEditMode && values && (
                                    <p
                                        className="text-gray-700 font-bold hover:text-base hover:bg-gray-200 px-3 py-1 hover:rounded-md cursor-pointer"
                                        onClick={() => {
                                            setSelectedValue(values);
                                            setShowPopup(true);
                                            setShowModal(false);
                                        }}
                                    >
                                        DELETE
                                    </p>
                                )}
                                <Button type="submit" disabled={isSubmitting} className="btn-explore-white">
                                    Save
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal>
            <Modal
                show={showLanguageModal}
                onHide={() => handleCloseWithConfirmation2(languageData)}
                centered backdrop="static" keyboard={false} className="zl"

            >
                <Modal.Header closeButton className="p-4">
                    <Modal.Title className="text-lg font-bold text-gray-900">{isEditModeLm ? "Edit Language" : "Add Language"}</Modal.Title>
                </Modal.Header>
                <Formik
                    enableReinitialize
                    innerRef={formikRef} // Ref to access Formik methods
                    initialValues={languageData}
                    validationSchema={languageSchema}
                    onSubmit={handleSubmitLanguage}
                >
                    {({ handleSubmit, setFieldValue, values, isSubmitting }) => {
                        return (
                            <Form onSubmit={handleSubmit} >
                                <div className="p-4 m-ml pb-0" style={{ height: modalHeight, transition: "height 0.10s linear" }}>
                                    {/* Language */}
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label>Language *</Form.Label>
                                        <Field
                                            type="text"
                                            name="language"
                                            className="form-control fomc-cls"
                                            placeholder="Search Language..."
                                            value={languageQuery}
                                            onChange={(e) => {
                                                setLanguageQuery(e.target.value);
                                                setFieldValue("language", e.target.value);
                                                fetchLanguages(e.target.value);
                                            }}
                                            onBlur={(e) => {
                                                // Delay hiding to allow onClick to process
                                                setTimeout(() => {
                                                    setLanguageSuggestions([]);
                                                }, 200); // Small delay to let onClick fire first
                                            }}
                                            autoComplete="off"
                                        />
                                        <ErrorMessage name="language" component="div" className="text-danger" />
                                        {languageSuggestions.length > 0 && (
                                            <ul className="list-group position-absolute w-100 mt-1 bg-white shadow-lg rounded language-suggestions">
                                                {languageSuggestions.map((lang, index) => (
                                                    <li
                                                        key={index}
                                                        className="list-group-item list-group-item-action"
                                                        onClick={() => {
                                                            setLanguageQuery(lang.language_name);
                                                            setFieldValue("language", lang.language_name);
                                                            setLanguageSuggestions([]);
                                                        }}
                                                    >
                                                        {lang.language_name}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </Form.Group>


                                    {/* Proficiency Certificate Radio */}
                                    <Form.Group className="mb-3 leading-[1.5]">
                                        <Form.Label className="mb-0 text-g">Do you have any proficiency certificate? *</Form.Label>
                                        <div>
                                            <label className="mr-3">
                                                <Field
                                                    type="radio"
                                                    name="languageProficiency"
                                                    value="yes"
                                                    onChange={(e) => {
                                                        setFieldValue("languageProficiency", e.target.value);
                                                        setLanguageProficiency("yes");
                                                    }}
                                                />{" "}
                                                Yes
                                            </label>
                                            <label>
                                                <Field
                                                    type="radio"
                                                    name="languageProficiency"
                                                    value="no"
                                                    onChange={(e) => {
                                                        setFieldValue("languageProficiency", e.target.value);
                                                        setLanguageProficiency("no");
                                                    }}
                                                />{" "}
                                                No
                                            </label>
                                        </div>
                                        <ErrorMessage name="languageProficiency" component="div" className="text-danger text-sm" />
                                    </Form.Group>

                                    {/* Show Proficiency Dropdown if "No" is selected */}
                                    {languageProficiency === "no" && (
                                        <Form.Group className="mb-[100px] leading-[1.5]">
                                            <Form.Label className="mb-0 text-g">Proficiency *</Form.Label>
                                            <Field as="select" name="proficiency" className="form-select fomc-cls">
                                                <option value="">Please Select</option>
                                                <option value="1">Beginner</option>
                                                <option value="2">Intermediate</option>
                                                <option value="3">Advanced</option>
                                                <option value="4">Fluent</option>
                                            </Field>
                                            <ErrorMessage name="proficiency" component="div" className="text-danger text-sm" />
                                        </Form.Group>
                                    )}

                                    {/* Show Test Details if "Yes" is selected */}
                                    {languageProficiency === "yes" && (
                                        <>
                                            <Form.Group className="mb-3 leading-[1.5]">
                                                <Form.Label className="mb-0 text-g">Test Name *</Form.Label>
                                                <Field type="text" name="testName" className="form-control fomc-cls" />
                                                <ErrorMessage name="testName" component="div" className="text-danger text-sm" />
                                            </Form.Group>

                                            <Row>
                                                <Col lg={4} md={12}>
                                                    <Form.Group className="mb-3 leading-[1.5]">
                                                        <Form.Label className="mb-0 text-g">Total Score *</Form.Label>
                                                        <Field type="text" name="totalScore" className="form-control fomc-cls" />
                                                        <ErrorMessage name="totalScore" component="div" className="text-danger text-sm" />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={2} md={12}>
                                                    <Form.Group className="mb-3 leading-[1.5]">
                                                        <Form.Label className="mb-0 text-g">Reading *</Form.Label>
                                                        <Field type="text" name="readingScore" className="form-control fomc-cls" />
                                                        <ErrorMessage name="readingScore" component="div" className="text-danger text-sm" />
                                                    </Form.Group>
                                                </Col >
                                                <Col lg={2} md={12}>
                                                    <Form.Group className="mb-3 leading-[1.5]">
                                                        <Form.Label className="mb-0 text-g">Listening *</Form.Label>
                                                        <Field type="text" name="listeningScore" className="form-control fomc-cls" />
                                                        <ErrorMessage name="listeningScore" component="div" className="text-danger text-sm" />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={2} md={12}>
                                                    <Form.Group className="mb-3 leading-[1.5]">
                                                        <Form.Label className="mb-0 text-g">Writing *</Form.Label>
                                                        <Field type="text" name="writingScore" className="form-control fomc-cls" />
                                                        <ErrorMessage name="writingScore" component="div" className="text-danger text-sm" />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={2} md={12}>
                                                    <Form.Group className="mb-3 leading-[1.5]">
                                                        <Form.Label className="mb-0 text-g">Speaking *</Form.Label>
                                                        <Field type="text" name="speakingScore" className="form-control fomc-cls" />
                                                        <ErrorMessage name="speakingScore" component="div" className="text-danger text-sm" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col lg={12} md={12}>
                                                    <Form.Group className="mb-3 w-100 leading-[1.5]" style={{ width: "100% !important" }}>
                                                        <div>
                                                            <Form.Label className="mb-0 text-g">Completion Date *</Form.Label>
                                                        </div>
                                                        <DatePicker
                                                            selected={values.completionDate}
                                                            onChange={(date) => setFieldValue("completionDate", date)}
                                                            dateFormat="MM/yyyy"
                                                            showMonthYearPicker
                                                            className="form-control fomc-cls"
                                                            placeholderText="Select Month & Year"
                                                            style={{ width: "100% !important" }}  // Inline CSS for full width
                                                        />
                                                        <ErrorMessage name="completionDate" component="div" className="text-danger text-sm" />
                                                    </Form.Group>
                                                </Col>
                                            </Row> */}
                                            <Row className="mb-3 leading-[1.5]">
                                                <Col>
                                                    <Form.Label className="mb-0 text-g">Completion Month *</Form.Label>
                                                    <Field as="select" name="completion_month" className="form-select fomc-cls">
                                                        <option value="">Month</option>
                                                        <option value="1">January</option>
                                                        <option value="2">February</option>
                                                        <option value="3">March</option>
                                                        <option value="4">April</option>
                                                        <option value="5">May</option>
                                                        <option value="6">June</option>
                                                        <option value="7">July</option>
                                                        <option value="8">August</option>
                                                        <option value="9">September</option>
                                                        <option value="10">October</option>
                                                        <option value="11">November</option>
                                                        <option value="12">December</option>
                                                    </Field>
                                                    <ErrorMessage name="completion_month" component="div" className="text-danger text-sm" />
                                                </Col>
                                                <Col>
                                                    <Form.Label className="mb-0 text-g">Completion Year *</Form.Label>
                                                    <Field as="select" name="completion_year" className="form-select fomc-cls ">
                                                        <option value="">Year</option>
                                                        {Array.from({ length: 100 }, (_, index) => {
                                                            const year = new Date().getFullYear() - index; // Get last 20 years
                                                            return <option key={year} value={year}>{year}</option>;
                                                        })}
                                                    </Field>
                                                    <ErrorMessage name="completion_year" component="div" className="text-danger text-sm" />
                                                </Col>
                                            </Row>


                                            {/* Score Card Upload */}

                                            <Form.Group className="mb-[40px] leading-[1.5]">
                                                <label htmlFor="file-upload" className="file-upload-label cursor-pointer">
                                                    <i className="fas fa-paperclip"></i> Upload Score Card *
                                                </label>
                                                <input
                                                    id="file-upload"
                                                    type="file"
                                                    className="hidden"
                                                    accept=".png, .jpg, .jpeg, .pdf"
                                                    name="scoreCard"
                                                    onChange={(event) => handleFileChange(event, setFieldValue)}
                                                />
                                                <ErrorMessage name="scoreCard" component="div" className="text-danger text-sm" />

                                                {(file || (!isEditModeLm && values.scoreCard) || (isEditModeLm && !file && values.score_card_url)) && (
                                                    <div className="file-preview bg-gray-200 p-2 rounded-md flex items-center justify-between mt-2">
                                                        <a
                                                            href={file ? "#" : values.score_card_url} // "#" for new file, URL for existing
                                                            onClick={(e) => {
                                                                e.preventDefault(); // Prevent default link behavior
                                                                if (file) {
                                                                    // Preview new file
                                                                    const reader = new FileReader();
                                                                    reader.onload = (e) => setImagePreview(e.target.result);
                                                                    reader.readAsDataURL(file);
                                                                } else if (values.score_card_url) {
                                                                    // Preview existing file
                                                                    handlePreview(values.score_card_url);
                                                                }
                                                            }}
                                                            className="text-blue-600 underline cursor-pointer"
                                                        >
                                                            {fileName || "Unnamed File"} <span className="text-gray-600 text-sm">({fileSize})</span>
                                                        </a>
                                                        <i
                                                            className="fas fa-times-circle text-gray-700 cursor-pointer"
                                                            onClick={() => handleRemoveFile(setFieldValue)}
                                                        ></i>
                                                    </div>
                                                )}
                                            </Form.Group>

                                        </>
                                    )}
                                </div>
                                <div className={`bottom-0 left-0 w-full bg-white p-4 border-t border-gray-300 flex rounded-b-lg ${isEditModeLm ? "justify-between" : "justify-end"}`}>
                                    {isEditModeLm && values && (
                                        <p
                                            className="text-gray-700 font-bold hover:text-base hover:bg-gray-200 px-3 py-1 hover:rounded-md cursor-pointer"
                                            onClick={() => {
                                                setSelectedValueL(values);
                                                setShowPopupL(true);
                                                setShowLanguageModal(false);
                                            }}
                                        >
                                            DELETE
                                        </p>
                                    )}

                                    <Button type="submit" disabled={isSubmitting} className="btn-explore-white">
                                        Save
                                    </Button>
                                </div>

                            </Form>
                        )
                    }}
                </Formik>
            </Modal>
            {showPopup && selectedValue && (
                <ConfirmationPopup
                    isOpen={showPopup}
                    title="Delete This Entry?"
                    message="This will permanently delete the record. This action cannot be undone."
                    onClose={() => setShowPopup(false)}
                    onConfirm={async () => {
                        await handleDelete(selectedValue);
                        setTimeout(() => {
                            window.location.reload();
                        }, 100);
                    }}
                    loading={loading} // Pass loading state to popup
                    showCancelButton={true}
                    onDiscard={false}
                    actionType="delete" // ✅ lowercase string
                />
            )}
            {showPopupL && selectedValueL && (
                <ConfirmationPopup
                    isOpen={showPopupL}
                    title="Delete This Entry?"
                    message="This will permanently delete the record. This action cannot be undone."
                    onClose={() => setShowPopupL(false)}
                    onConfirm={async () => {
                        await handleDeleteL(selectedValueL);
                        setTimeout(() => {
                            window.location.reload();
                        }, 100);
                    }}
                    loading={loading} // Pass loading state to popup
                    showCancelButton={true}
                    onDiscard={false}
                    actionType="delete" // ✅ lowercase string
                />
            )}
            {popupDataE.isOpen && (
                <ConfirmPopupShareLink
                    isOpen={popupDataE.isOpen}
                    title={popupDataE.title}
                    message={popupDataE.message}
                    onClose={() => setPopupDataE({ isOpen: false })}
                    onConfirm={() => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 300);
                    }}
                    showCancelButton={false}
                    onDiscard={false}
                />
            )}
            {popupData.isOpen && (
                <ConfirmationPopup
                    isOpen={popupData.isOpen}
                    title={popupData.title}
                    message={popupData.message}
                    onClose={() => setPopupData({ isOpen: false })}
                    onConfirm={() => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 300);
                    }}
                    showCancelButton={false}
                    onDiscard={false}
                />
            )}
            {showPopupC && (
                <ConfirmationPopup
                    isOpen={showPopupC}
                    title="Discard Changes?"
                    message="Are you sure you want to discard all changes?"
                    onClose={() => setShowPopupC(false)}
                    onDiscard={async () => {
                        setShowModal(false)
                        setShowPopupC(false);
                    }}
                    loading={loading}
                    showCancelButton={true}
                    onConfirm={false}
                />
            )}
            {showPopupCe && (
                <ConfirmationPopup
                    isOpen={showPopupCe}
                    title="Discard Changes?"
                    message="Are you sure you want to discard all changes?"
                    onClose={() => setShowPopupCe(false)}
                    onDiscard={async () => {
                        setShowPopupCe(false); // Pehle confirmation popup band karein
                        setTimeout(() => {
                            setShowLanguageModal(false);
                            setLanguageProficiency(""); // Reset state when closing
                            formikRef.current?.resetForm(); // Reset form fields
                        }, 300); // Small delay to allow popup animation
                    }}
                    loading={loading}
                    showCancelButton={true}
                    onConfirm={false}
                />
            )}


        </>
    );
};

export default Acedmic;
