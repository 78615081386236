// src/components/MediaUpload/MediaUpload.js
import React from 'react';

const MediaUpload = ({ onUpload, mediaFiles, setError }) => {
    const MAX_IMAGE_LIMIT = 10;
    const MAX_IMAGE_SIZE_MB = 1;
    const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/png", "image/jpg"];

    const getImageCount = (files) => {
        return files.filter((media) => media.file.type.startsWith('image/')).length;
    };

    const handleFileChange = (e) => {
        if (e.target.files) {
            const currentImageCount = getImageCount(mediaFiles);
            const selectedFiles = Array.from(e.target.files);

            const imageFiles = selectedFiles.filter((file) => file.type.startsWith('image/'));
            const imageCount = imageFiles.length;
            const totalImageCount = currentImageCount + imageCount;

            // Type or count validation
            if (imageCount > MAX_IMAGE_LIMIT) {
                setError(`You cannot select more than ${MAX_IMAGE_LIMIT} images at a time.`);
                e.target.value = '';
                return;
            }

            if (totalImageCount > MAX_IMAGE_LIMIT) {
                setError(`You cannot upload more than ${MAX_IMAGE_LIMIT} images in total.`);
                e.target.value = '';
                return;
            }

            // Size or format validation
            for (let file of imageFiles) {
                if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
                    setError("Only JPG, JPEG, and PNG images are allowed.");
                    e.target.value = '';
                    return;
                }

                if (file.size / 1024 / 1024 > MAX_IMAGE_SIZE_MB) {
                    setError("Each image must be less than 1MB.");
                    e.target.value = '';
                    return;
                }
            }

            setError(""); // Clear any previous errors
            onUpload(e.target.files);
        }
    };

    return (
        <label className="cursor-pointer text-gray-500 hover:text-blue-500 flex items-center space-x-1">
            <i className="fas fa-image text-[16px]"></i>
            <input
                id="media-upload-input"
                type="file"
                multiple
                accept="image/*,video/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
        </label>
    );
};

export default MediaUpload;
