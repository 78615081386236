import React, { useEffect, useState, useRef } from 'react';
import Accordions from '../../Components/Accordion/AccordionRatingView'
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import API from '../../Api/Api';
import { getStarColorClass, getRatingLabel } from '../../Functions/RatingLabelColor';
import { IMAGE_PATHS, DOMAIN_URL } from '../../Functions/Constant';
import Spinner from 'react-bootstrap/Spinner';
import InfoTooltip from '../../Components/InfoTooltip'
import StarRating from '../../Components/StarRating'
import TextWithReadMore from '../../Components/Readmore'
import ShareLinkModal from "../../Components/ShareLinkModal";

import ConfirmationPopup from '../../Components/ConfirmationPopup'
import ConfirmPopupShareLink from '../../Components/ConfirmPopupShareLink'



const ProfessionalSummary = ({ scrollToReviews, professional, ratingsPorfin,experienceRef }) => {
    const [show, setShow] = useState(false);
    const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [experiences, setExperiences] = useState([]);
    const [query, setQuery] = useState("");
    const [locationquery, setLocationquery] = useState("");
    const [locationsuggestions, setLocationSuggestions] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupC, setShowPopupC] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const formikRef = useRef(null);
    const [showFullq, setShowFullq] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [popupData, setPopupData] = useState({
        isOpen: false,
        title: "",
        message: "",
        onClose: () => { },
        onConfirm: () => { }
    });

    const [experience, setExperience] = useState({
        title: "",
        employmentType: "",
        company: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        isCurrentlyWorking: false,
        location: "",
        locationType: "",
        jobDescription: "",
        profileHeadline: "",
        startSalary: "",
        lastSalary: "",
        experience_id: "",
        otherCompany: ""
    });

    // Open & Close Modal
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required("Title is required")
            .test(
                "wordCount",
                "Title cannot exceed 10 words", // ✅ Custom error message
                (value) => value && value.trim().split(/\s+/).length <= 10 // ✅ Check word count
            ),
        employmentType: Yup.string().required("Employment type is required"),
        company: Yup.string().required("Company name is required"),

        startMonth: Yup.string().required("Start month is required"),
        startYear: Yup.string().required("Start year is required"),
        endMonth: Yup.string().when("isCurrentlyWorking", {
            is: false,
            then: (schema) => schema.required("End month is required"),
            otherwise: (schema) => schema.notRequired().nullable(),
        }),
        endYear: Yup.string().when("isCurrentlyWorking", {
            is: false,
            then: (schema) => schema.required("End year is required"),
            otherwise: (schema) => schema.notRequired().nullable(),
        }),
        startSalary: Yup.number()
            .typeError("Start salary must be a number")
            .positive("Salary must be a positive number")
            .test("len", "Start salary must be at most 10 digits", (value) =>
                !value || value.toString().length <= 10
            )
            .notRequired(),

        lastSalary: Yup.number()
            .typeError("Salary must be a number")
            .positive("Salary must be a positive number")
            .test("len", "Salary must be at most 10 digits", (value) =>
                !value || value.toString().length <= 10
            )
            .notRequired(),
        location: Yup.string().required("Location is required"),
        locationType: Yup.string().required("Location type is required"),
        jobDescription: Yup.string()
            .min(10, "Description must be at least 20 characters")
            .required("Job description is required"),
        // profileHeadline: Yup.string().required("Profile headline is required"),
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setLoading(true);
        try {
            //console.log("Submitting Form:", values);
            const payload = values.experience_id && values.experience_id > 0 ? {
                title: values.title,
                employment_type: values.employmentType,
                company: values.company,
                start_month: values.startMonth,
                start_year: parseInt(values.startYear),
                end_month: values.isCurrentlyWorking ? null : values.endMonth,
                end_year: values.isCurrentlyWorking ? null : values.endYear,
                is_current: values.isCurrentlyWorking ? 1 : 0,
                location: values.location,
                location_type_id: values.locationType,
                description: values.jobDescription,
                profile_headline: '',
                start_salary: values.startSalary === '' ? null : values.startSalary,
                last_salary: values.lastSalary === '' ? null : values.lastSalary,
                experience_id: values.experience_id,
            } : {
                title: values.title,
                employment_type: values.employmentType,
                company: values.company,
                start_month: values.startMonth,
                start_year: parseInt(values.startYear),
                end_month: values.isCurrentlyWorking ? null : values.endMonth,
                end_year: values.isCurrentlyWorking ? null : values.endYear,
                is_current: values.isCurrentlyWorking ? 1 : 0,
                location: values.location,
                location_type_id: values.locationType,
                description: values.jobDescription,
                profile_headline: '',
                start_salary: values.startSalary === '' ? null : values.startSalary,
                last_salary: values.lastSalary === '' ? null : values.lastSalary,
            };

            //console.log("Submitting Payload:", payload);
            if (values.experience_id && values.experience_id > 0) {
                const response = await API.put(`/experiences/${values.experience_id}`, payload);
            }
            else {
                const response = await API.post('/experiences', payload);
            }
            fetchExperience();
            resetForm();
            handleClose();
            setPopupData({
                isOpen: true,
                message: "Your Profile Has been Updated.",
                onConfirm: () => setPopupData({ isOpen: false }), // Close Popup on Confirm
                onClose: () => setPopupData({ isOpen: false })    // Close Popup on Close
            });
        } catch (error) {
            console.error(" Error submitting form:", error);
        } finally {
            setLoading(false);
            setSubmitting(false);
        }
    };

    const fetchCompanies = async (search) => {
        if (!search) {
            setSuggestions([]);
            return;
        }
        setLoading(true);
        try {
            const response = await API.get(`/autocomplete/company?query=${search}`);
            console.log("Raw API Response:", response); // Debugging: Check API response

            // Extract the array properly from response
            const companyList = Array.isArray(response) ? response : [];

            console.log("Extracted Company List:", companyList); // Debugging: Check extracted list

            setSuggestions(companyList); // Update state with extracted list
        } catch (error) {
            console.error("Error fetching companies:", error);
            setSuggestions([]);
        } finally {
            setLoading(false);
        }
    };
    const fetchLocation = async (search) => {
        if (!search) {
            setLocationSuggestions([]);
            return;
        }
        setLoading(true);
        try {
            const response = await API.get(`/autocomplete/locations?query=${search}`);
            console.log("Raw API Response:", response); // Debugging: Check API response

            // Extract the array properly from response
            const locationList = Array.isArray(response) ? response : [];

            console.log("Extracted locationList List:", locationList); // Debugging: Check extracted list

            setLocationSuggestions(locationList); // Update state with extracted list
        } catch (error) {
            console.error("Error fetching locationList:", error);
            setLocationSuggestions([]);
        } finally {
            setLoading(false);
        }
    };

    const formatExperienceDate = (start_month, start_year, end_month, end_year) => {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        // Get current date if end date is null (present job)
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        // Convert month number to abbreviation
        const startMonthStr = months[start_month - 1];
        const endMonthStr = end_month ? months[end_month - 1] : null; // Don’t set "Present" here yet

        // If still working, use "Present" only once
        const endYearStr = end_year ? end_year : (!end_month ? "Present" : ""); // Condition to avoid duplicate "Present"

        // Calculate experience duration
        const startDate = new Date(start_year, start_month - 1);
        const endDate = end_year ? new Date(end_year, (end_month || 12) - 1) : currentDate;

        let totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
        let years = Math.floor(totalMonths / 12);
        let monthsDiff = totalMonths % 12;

        // Final formatted string with condition to handle "Present"
        return `${startMonthStr} ${start_year} - ${endMonthStr ? endMonthStr + ' ' : ''}${endYearStr} (${years} yrs ${monthsDiff} mon)`;
    };



    const fetchExperience = async () => {
        const response = await API.get(`/experiences`);
        setExperiences(response.experiences);
        console.log(response);
    };
    useEffect(() => {
        fetchExperience();
    }, []);
    const editExperience = (experience) => {
        handleShow();
        //console.log(experience);
        setExperience({
            title: experience.title,
            employmentType: experience.employment_type_id,
            company: experience.company_name,
            startMonth: experience.start_month,
            startYear: experience.start_year,
            endMonth: experience.end_month,
            endYear: experience.end_year,
            isCurrentlyWorking: experience.is_current == 1 ? true : false,
            location: experience.city_id,
            locationType: experience.location_type_id,
            jobDescription: experience.description,
            profileHeadline: '',
            startSalary: experience.start_salary,
            lastSalary: experience.last_salary,
            experience_id: experience.experience_id,
        });
        setQuery(experience.company_name);
        setLocationquery(experience.location);
        setIsEditMode(true);
        setSuggestions([]);
        setLocationSuggestions([]);
    };
    const addExperience = () => {
        setExperience({
            title: "",
            employmentType: "",
            company: "",
            startMonth: "",
            startYear: "",
            endMonth: "",
            endYear: "",
            isCurrentlyWorking: false,
            location: "",
            locationType: "",
            jobDescription: "",
            profileHeadline: "",
            startSalary: "",
            lastSalary: "",
            experience_id: "",
        });
        setQuery("");
        setLocationquery("");
        handleShow();
        setIsEditMode(false);
        setSuggestions([]);
        setLocationSuggestions([]);
    };
    const [visibleSkills, setVisibleSkills] = useState(10);
    const loadMoreSkills = () => {
        setVisibleSkills((prev) => prev + 10);
    };


    const handleDelete = async (values) => {
        setLoading(true); // Loader start
        try {
            const response = await API.delete(`/experiences/${values.experience_id}`);
            fetchExperience();
            setShowPopup(false);
        } catch (error) {
            console.error("Error deleting:", error);
        }

        setLoading(false); // Loader stop
    };
    const handleCloseWithConfirmation = (values) => {


        const currentValues = formikRef.current?.values || {};
        const isFormFilled = Object.values(currentValues).some(val => val && val !== "");
        if (!isEditMode && isFormFilled) {
            setShowPopupC(true); // Show confirmation popup
        } else {
            handleClose();
            setTimeout(() => {
                formikRef.current?.resetForm(); // Reset form fields
            }, 100);
        }

    };
    const handleCreateLink = () => {
        setShowModal(true);
    };

    return (
        <>

            {
                ratingsPorfin?.total_reviews > 0 ? (<section className=" pt-[40px] pb-[20px] px-4 ">
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between items-baseline">
                                    <div>
                                        <h3 className="text-[28px] font-bold text-black  mb-1 leading-[1.5]">Professional Reviews</h3>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="rounded-lg p-6 pt-0 border-1 bg-1 custm-bg shdo-box" >
                            <h3 className="text-[24px] font-bold mb-0 text-[#000]">Summary</h3>
                            <hr className="border-gray-300 mb-4 border-t-1" />
                            <Col lg={6} md={12} className="mb-6">
                                <div className="flex items-start space-x-3 mb-3">
                                    <div className="mt-[8px]">
                                        <span className="text-[40px] font-bold text-gray-900 lt">{ratingsPorfin.overall_rating.toFixed(1) ?? "0.0"}</span>
                                    </div>
                                    <div className="leading-[1.4]">
                                        <span className="text-[16px] font-bold text-gray-900">{getRatingLabel(ratingsPorfin?.overall_rating)}</span>
                                        <div className="flex items-center  mb-2">
                                            {/* {[...Array(5)].map((_, index) => (
                                                <i
                                                    key={index}
                                                    className={`fas fa-star text-[16px] ${index < Math.round(ratingsPorfin?.overall_rating ?? 0)
                                                        ? "yellow-i"
                                                        : "text-gray-300"
                                                        }`}
                                                ></i>
                                            ))} */}
                                            <StarRating rating={ratingsPorfin?.overall_rating} starSize={15} />
                                            <span className="text-gray-600 text-[14px] ml-2 cursor-pointer hover:underline hover:text-black transition" onClick={scrollToReviews}>
                                                {ratingsPorfin?.total_reviews ?? 0} Reviews
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-[#74787F] text-white text-[14px] px-3 py-0 rounded-[0.6rem] inline-block shdo-box">
                                    This summary is created by AI, based on recent reviews.
                                </div>
                                <p className="text-gray-700 text-[14px] mt-3 leading-[1.5]">
                                    <TextWithReadMore text={professional?.ai_summary} />
                                </p>

                                {professional.key_strengths != null && Array.isArray(professional.key_strengths) && professional.key_strengths.length > 0 ? (
                                    <div className="mt-2">
                                        <h4 className="font-bold text-gray-900 text-[16px] mb-2">Key Strengths</h4>
                                        <div className="flex flex-wrap gap-2">
                                            {
                                                professional.key_strengths.map((skill, index) => (
                                                    <span key={index} className="flex items-center px-2 py-0 text-sm font-medium text-black rounded-full gap-2 h-m bg-white">
                                                        <i className={`fas fa-star ${getStarColorClass(skill.rating)}`}></i>
                                                        <span className="black-class"> {skill.name} </span>
                                                        <span className="text-gray-500"> ({skill.total})</span>
                                                    </span>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {/* Improvement Areas */}
                                {professional.improvement_areas != null && Array.isArray(professional.improvement_areas) && professional.improvement_areas.length > 0 ? (
                                    <div className="mt-2">
                                        <h4 className="font-bold text-gray-900 text-[16px] mb-2">Improvement Areas</h4>
                                        <div className="flex flex-wrap gap-2">
                                            {
                                                professional.improvement_areas.map((weakness, index) => (
                                                    <span key={index} className="flex items-center px-2 py-0 font-medium text-black text-sm rounded-full gap-2 h-m bg-white">
                                                        <i className={`fas fa-star ${getStarColorClass(weakness.rating)}`}></i>
                                                        <span className="black-class"> {weakness.name} </span>
                                                        <span className="text-gray-500"> ({weakness.total})</span>
                                                    </span>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </Col>

                            <Col lg={6} md={12} className="">
                                <Accordions

                                    data={Array.isArray(professional?.category_ratings) ? professional.category_ratings : []}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>) : (
                    <></>
                )
            }

            <section className="pb-[15px] px-4 " ref={experienceRef}>
                <Container>
                    {/* Experience Header */}
                    <Row>
                        <Col xs={12} md={12} className={`${experiences.length === 0 ? "p-0" : ""}`}>
                            <div className={`flex justify-between items-baseline  ${experiences.length === 0 ? "bg-white rounded-lg p-6 border border-gray-200  shadow-lg" : ""}`}>
                                <div>
                                    <h3 className={`text-[22px] font-bold text-gray-900 mb-2 leading-[1.4] ${experiences.length === 0 ? "mt-1" : "mt-3"}`}>Experience <InfoTooltip text="Add work experience to get feedback from colleagues and managers." /></h3>
                                </div>
                                <div className="cl-plu">
                                    <i className="fas fa-plus black-class font-thin text-[27px] cursor-pointer i-p" onClick={addExperience}></i>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {
                        experiences.map((experience, index) => (
                            <Row className="bg-white rounded-lg p-6 border-1 mb-4 !pr-[14px]">
                                <Col lg={1} md={2} sm={12}>
                                    <img
                                        src={IMAGE_PATHS.BUILDING}
                                        className="w-[60px] h-[60px] bg-gray-300 rounded-md"
                                    />
                                </Col>
                                <Col lg={10} md={9} sm={11} className="leading-[1.4]">
                                    <h4 className="text-lg font-bold text-gray-900 mb-0">{experience.title}</h4>
                                    <p className="text-gray-700 overflow-hidden flex">
                                        <span> {experience.company_name.length > 28 ? `${experience.company_name.substring(0, 28)}... ` : experience.company_name}
                                        </span><span className="ml-2"> | {experience.employment_type}</span></p>

                                    <p className="text-gray-700">
                                        {formatExperienceDate(experience.start_month, experience.start_year, experience.end_month, experience.end_year)}
                                    </p>
                                    <p className="text-gray-700">{experience.location}</p>
                                    <p className="text-gray-700 mt-2">
                                        {experience.description}
                                    </p>
                                </Col>
                                <Col lg={1} md={1} sm={1} className="pr-0 flex justify-end">
                                    <div className="cl-plu"> <i className="fas fa-pencil-alt black-class font-bold text-[15px] cursor-pointer i-p !top-[5px]" onClick={() => editExperience(experience)}  ></i></div>

                                </Col>

                                {
                                    experience.overall_rating != null && experience.overall_rating.total_reviews > 0 ? (<>
                                        <hr className="border-gray-300 my-4 border-t-1" />
                                        <Col lg={2} md={12} className="text-start">
                                            <h1 className="text-[40px] font-bold text-gray-900 mb-0 leading-[1] lt">{experience.overall_rating.overall_rating}</h1>
                                            <p className="text-gray-600 font-semibold">{getRatingLabel(experience.overall_rating.overall_rating)}</p>
                                            <div className="yellow-i text-lg">

                                                <StarRating rating={experience.overall_rating.overall_rating} starSize={15} />
                                            </div>
                                            <p className="text-gray-500 text-sm cursor-pointer hover:underline hover:text-black transition" onClick={scrollToReviews}>{experience.overall_rating.total_reviews} Reviews</p>
                                        </Col>

                                        {/* Rating Breakdown */}
                                        <Col lg={4} md={12} sm={12} className="border-r border-gray-300">
                                            <div className="space-y-1">
                                                {[
                                                    { label: "Excellent", value: parseInt(experience.overall_rating.excellent) },
                                                    { label: "Good", value: parseInt(experience.overall_rating.very_good) },
                                                    { label: "Average", value: parseInt(experience.overall_rating.average) },
                                                    { label: "Poor", value: parseInt(experience.overall_rating.poor) },
                                                    { label: "Terrible", value: parseInt(experience.overall_rating.terrible) },
                                                ].map((item, index) => (
                                                    <div key={index} className="flex items-center w-full">
                                                        {/* Label */}
                                                        <span className="text-gray-900 text-sm font-medium w-[25%]">{item.label}</span>

                                                        {/* Progress Bar */}
                                                        <div className="w-[55%] h-[12px] bg-gray-200 rounded-full overflow-hidden mx-2">
                                                            <div
                                                                className="h-full yellow-b rounded-full"
                                                                style={{
                                                                    width: `${experience.overall_rating.total_reviews > 0
                                                                            ? (item.value / experience.overall_rating.total_reviews) * 100
                                                                            : 0
                                                                        }%`,
                                                                }}
                                                            ></div>
                                                        </div>

                                                        {/* Count */}
                                                        <span className="text-gray-900 text-sm font-medium w-[15%] text-start">
                                                            {item.value}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                        <Col lg={6} md={12} sm={12} className="pl-[45px]">
                                            <div className="grid grid-cols-2 sm:grid-cols-2 gap-y-2 gap-x-4">
                                                {experience.categories.map((item, index) => (
                                                    <div key={index} className="flex flex-col justify-center items-start leading-[1]">
                                                        <div className="leading-[1.4] flex items-center h-[20px]">
                                                            <span className="font-medium text-[14px] text-[#000]">{item.name}</span>
                                                        </div>
                                                        <div className="flex items-center w-full">
                                                            <div className="w-[78%] h-[12px] bg-gray-200 rounded-full overflow-hidden mr-2">
                                                                <div className="h-full yellow-b" style={{ width: `${(item.avg_rating / 5) * 100}%` }}></div>
                                                            </div>
                                                            <div>
                                                                <span className="text-[#000] text-[14px]">{item.avg_rating.toFixed(1)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>


                                        {/* Tags Section */}
                                        <Row className="mt-4">
                                            <Col lg={12}>
                                                <div className="flex flex-wrap gap-2 mt-2">
                                                    {
                                                        experience.skills.slice(0, visibleSkills).map((skill, index) => {
                                                            let starColor = getStarColorClass(skill.avg_rating);
                                                            return (
                                                                <span
                                                                    key={index}
                                                                    className="flex items-center px-2 py-0 text-sm font-medium text-black rounded-full gap-2 h-m bg-white"
                                                                >
                                                                    <i className={`fas fa-star mr-1 ${starColor}`}></i>
                                                                    <span className="black-class">{skill.name}</span>
                                                                    <span className="text-gray-500"> ({skill.total_ratings})</span>
                                                                </span>
                                                            );
                                                        })
                                                    }
                                                    {visibleSkills < experience.skills.length && (
                                                        <button
                                                            onClick={loadMoreSkills}
                                                            className="text-black font-bold text-sm cursor-pointer hover:bg-gray-200 px-3 py-1 rounded"
                                                        >
                                                            Load More
                                                        </button>
                                                    )}
                                                </div>

                                                {/* ✅ Load More Button */}


                                            </Col>
                                        </Row>

                                        {/* CTA Button */}
                                        <Row className="mt-6 pr-[7px]">
                                            <Col lg={6} >
                                                <button className="btn-explore-white" onClick={scrollToReviews}>
                                                    <span className="mr-2">Jump to reviews</span>
                                                    <i className="fas fa-arrow-down"></i>
                                                </button>
                                            </Col>
                                            <Col lg={6} className="flex justify-end !pr-[0px]">
                                                <button
                                                    className="btn-explore-white"
                                                    onClick={() => setShowModal(true)}
                                                >
                                                    <span className="mr-2">Get more ratings</span>
                                                </button>
                                                <ShareLinkModal
                                                    show={showModal}
                                                    onClose={() => setShowModal(false)}
                                                    onCreateLink={handleCreateLink}
                                                />
                                            </Col>
                                        </Row>
                                    </>) : (<>
                                        <Row className="mt-6 pr-[7px]">
                                            <Col lg={6} >

                                            </Col>
                                            <Col lg={6} className="flex justify-end !pr-[0px]">
                                                <button
                                                    className="btn-explore-white"
                                                    onClick={() => setShowModal(true)}
                                                >
                                                    <span className="mr-2">Get more ratings</span>
                                                </button>
                                                <ShareLinkModal
                                                    show={showModal}
                                                    onClose={() => setShowModal(false)}
                                                    onCreateLink={handleCreateLink}
                                                />
                                            </Col>
                                        </Row>
                                    </>)
                                }

                            </Row>
                        ))
                    }

                </Container>
            </section>


            {/* Modal for Adding Experience */}
            <Modal show={show} onHide={() => handleCloseWithConfirmation(experience)} centered backdrop="static" keyboard={false} className="zl">
                <Modal.Header closeButton className="p-4">
                    <Modal.Title className="text-lg font-bold text-gray-900">{isEditMode ? "Edit Experience" : "Add Experience"}</Modal.Title>
                </Modal.Header>
                <Formik
                    enableReinitialize
                    initialValues={experience}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    innerRef={formikRef}
                >
                    {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
                        <Form onSubmit={handleSubmit} >
                            <div className="relative pb-0 m-h p-[28px]">

                                {/* Title */}
                                <Form.Group className="mb-3 leading-[1.5]">
                                    <Form.Label className="mb-0 text-g">Title *</Form.Label>
                                    <Field type="text" name="title" className="form-control fomc-cls" placeholder="Ex: Retail Sales Manager" />
                                    <ErrorMessage name="title" component="div" className="text-danger text-sm" />
                                </Form.Group>

                                {/* Employment Type */}
                                <Form.Group className="mb-3 leading-[1.5]">
                                    <Form.Label className="mb-0 text-g" >Employment Type *</Form.Label>
                                    <Field as="select" name="employmentType" className="form-select fomc-cls">
                                        <option value="">Please select</option>
                                        <option value="1">Full-time</option>
                                        <option value="2">Part-time</option>
                                    </Field>
                                    <ErrorMessage name="employmentType" component="div" className="text-danger text-sm" />
                                </Form.Group>

                                {/* Company */}
                                <Form.Group className="mb-3 position-relative">
                                    <Form.Label className="mb-0 text-g">Company *</Form.Label>
                                    <Field
                                        type="text"
                                        name="company"
                                        className="form-control fomc-cls"
                                        placeholder="Search Company..."
                                        value={query}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setQuery(value);
                                            setFieldValue("company", value); // Updates Formik field value

                                            if (value.length > 1) {
                                                fetchCompanies(value); // Call API only after 2+ chars
                                            } else {
                                                setSuggestions([]); // Reset dropdown when input is empty
                                            }
                                        }}
                                        onBlur={(e) => {
                                            // Delay hiding to allow onClick to process
                                            setTimeout(() => {
                                                setSuggestions([]);
                                            }, 200); // Small delay to let onClick fire first
                                        }}
                                        autoComplete="off"
                                    />
                                    <ErrorMessage name="company" component="div" className="text-danger text-sm" />

                                    {suggestions.length > 0 && (
                                        <ul className="list-group position-absolute w-100 mt-1 bg-white shadow-lg rounded z-50">
                                            {suggestions.map((company, index) => (
                                                <li
                                                    key={company.company_id} // Unique key based on ID
                                                    className="list-group-item list-group-item-action"
                                                    onClick={() => {
                                                        setQuery(company.company_name);
                                                        setFieldValue("company", company.company_name);
                                                        setSuggestions([]); // Hide dropdown after selection
                                                    }}
                                                >
                                                    {company.company_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}



                                </Form.Group>

                                {/* Currently Working Checkbox */}
                                <Form.Group className="mb-3 leading-[1.5]">
                                    <Field
                                        className="check-cl fomc-cls"
                                        type="checkbox"
                                        name="isCurrentlyWorking"
                                        checked={values.isCurrentlyWorking}
                                        onChange={() => {
                                            setFieldValue("isCurrentlyWorking", !values.isCurrentlyWorking);
                                            if (!values.isCurrentlyWorking) {
                                                setFieldValue("endMonth", "");
                                                setFieldValue("endYear", "");
                                            }
                                        }}
                                    />
                                    <Form.Label className="ml-2">I am currently working in this role</Form.Label>
                                </Form.Group>

                                {/* Start Date */}
                                <Row className="mb-3 leading-[1.5]">
                                    <Col>
                                        <Form.Label className="mb-0 text-g">Start Month *</Form.Label>
                                        <Field as="select" name="startMonth" className="form-select fomc-cls">
                                            <option value="">Month</option>
                                            <option value="1">January</option>
                                            <option value="2">February</option>
                                            <option value="3">March</option>
                                            <option value="4">April</option>
                                            <option value="5">May</option>
                                            <option value="6">June</option>
                                            <option value="7">July</option>
                                            <option value="8">August</option>
                                            <option value="9">September</option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                        </Field>
                                        <ErrorMessage name="startMonth" component="div" className="text-danger text-sm" />
                                    </Col>
                                    <Col>
                                        <Form.Label className="mb-0 text-g">Start Year *</Form.Label>
                                        <Field as="select" name="startYear" className="form-select fomc-cls ">
                                            <option value="">Year</option>
                                            {Array.from({ length: 100 }, (_, index) => {
                                                const year = new Date().getFullYear() - index; // Get last 20 years
                                                return <option key={year} value={year}>{year}</option>;
                                            })}
                                        </Field>
                                        <ErrorMessage name="startYear" component="div" className="text-danger text-sm" />
                                    </Col>
                                </Row>

                                {/* End Date (Disabled if Currently Working) */}
                                <Row className="mb-3 leading-[1.5]">
                                    <Col>
                                        <Form.Label className="mb-0 text-g">End Month *</Form.Label>
                                        <Field as="select" name="endMonth" className="form-select fomc-cls" disabled={values.isCurrentlyWorking}>

                                            <option value="">Month</option>
                                            <option value="1">January</option>
                                            <option value="2">February</option>
                                            <option value="3">March</option>
                                            <option value="4">April</option>
                                            <option value="5">May</option>
                                            <option value="6">June</option>
                                            <option value="7">July</option>
                                            <option value="8">August</option>
                                            <option value="9">September</option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                        </Field>
                                        <ErrorMessage name="endMonth" component="div" className="text-danger text-sm" />
                                    </Col>
                                    <Col>
                                        <Form.Label className="mb-0 text-g">End Year *</Form.Label>

                                        <Field as="select" name="endYear" className="form-select fomc-cls" disabled={values.isCurrentlyWorking}>
                                            <option value="">Year</option>
                                            {Array.from({ length: 100 }, (_, index) => {
                                                const year = new Date().getFullYear() - index; // Get last 20 years
                                                return <option key={year} value={year}>{year}</option>;
                                            })}
                                        </Field>
                                        <ErrorMessage name="endYear" component="div" className="text-danger text-sm" />
                                    </Col>
                                </Row>
                                <Row className="mb-3 leading-[1.5]">
                                    <Col>
                                        {/*start salary */}
                                        <Form.Group className="leading-[1.5]">
                                            <Form.Label className="mb-0 text-g">Start Salary *</Form.Label>
                                            <Field type="text" name="startSalary" className="form-control fomc-cls" placeholder="Start Salary..." />
                                            <ErrorMessage name="startSalary" component="div" className="text-danger text-sm" />

                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        {/* last salary */}
                                        <Form.Group className="leading-[1.5]">
                                            <Form.Label className="mb-0 text-g">{values.isCurrentlyWorking ? 'Current' : 'Last'} Salary *</Form.Label>
                                            <Field type="text" name="lastSalary" className="form-control fomc-cls" placeholder="Salary..." />
                                            <ErrorMessage name="lastSalary" component="div" className="text-danger text-sm" />

                                        </Form.Group>
                                    </Col>

                                </Row>
                                {/* Location */}
                                <Form.Group className="mb-3 position-relative">
                                    <Form.Label className="mb-0 text-g">Location *</Form.Label>
                                    <Field
                                        type="text"
                                        name="location"
                                        className="form-control fomc-cls"
                                        placeholder="Location..."
                                        value={locationquery}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setLocationquery(value);
                                            //setFieldValue("location", value); // Updates Formik field value

                                            if (value.length > 1) {
                                                fetchLocation(value); // Call API only after 2+ chars
                                            } else {
                                                setFieldValue("location", '');
                                                setLocationSuggestions([]); // Reset dropdown when input is empty
                                            }
                                        }}
                                        onBlur={(e) => {
                                            // Delay hiding to allow onClick to process
                                            setTimeout(() => {
                                                setLocationSuggestions([]);
                                            }, 200); // Small delay to let onClick fire first
                                        }}
                                        autoComplete="off"
                                    />
                                    <ErrorMessage name="location" component="div" className="text-danger text-sm" />

                                    {locationsuggestions.length > 0 && (
                                        <ul className="list-group position-absolute w-100 mt-1 bg-white shadow-lg rounded z-50">
                                            {locationsuggestions.map((locationVal, index) => (
                                                <li
                                                    key={locationVal.city_id} // Unique key based on ID
                                                    className="list-group-item list-group-item-action"
                                                    onClick={() => {
                                                        setLocationquery(locationVal.city_name + `, ` + locationVal.state_name + `, ` + locationVal.country_name);
                                                        setFieldValue("location", locationVal.city_id);
                                                        setLocationSuggestions([]); // Hide dropdown after selection
                                                    }}
                                                >
                                                    {locationVal.city_name + `, ` + locationVal.state_name + `, ` + locationVal.country_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}



                                </Form.Group>
                                {/* Location Type */}
                                <Form.Group className="mb-3 leading-[1.5]">
                                    <Form.Label className="mb-0 text-g">Location Type *</Form.Label>
                                    <Field as="select" name="locationType" className="form-select fomc-cls">
                                        <option value="">Please select</option>
                                        <option value="1">Remote</option>
                                        <option value="2">On-site</option>
                                    </Field>
                                    <ErrorMessage name="locationType" component="div" className="text-danger text-sm" />
                                </Form.Group>

                                {/* Job Description */}
                                <Form.Group className="leading-[1.5] mb-[100px]">
                                    <Form.Label className="mb-0 text-g">Description *</Form.Label>
                                    <Field name="jobDescription">
                                        {({ field, form }) => (
                                            <div>
                                                <textarea
                                                    {...field}
                                                    className="form-control fomc-cls "
                                                    rows={3}
                                                    style={{ resize: "none" }}
                                                    maxLength={200} // Prevents input beyond 200 characters
                                                    placeholder="List your major duties and successes, highlighting specific projects."
                                                    onChange={(e) => {
                                                        if (e.target.value.length <= 200) {
                                                            form.setFieldValue("jobDescription", e.target.value);
                                                        }
                                                    }}
                                                />
                                                <div className="relative">
                                                    <div className="flex flex-col">
                                                        <ErrorMessage name="jobDescription" component="div" className="text-danger text-sm" />
                                                        <div className="absolute right-0  text-sm text-gray-500">
                                                            {field.value.length}/200
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Field>

                                </Form.Group>

                                {/* <Form.Group className="mb-[100px] leading-[1.5]">
                                    <Form.Label className="mb-0 text-g">Profile Headline *</Form.Label>
                                    <Field type="text" name="profileHeadline" className="form-control fomc-cls" placeholder="Ex: London, United Kingdom" />
                                    <ErrorMessage name="profileHeadline" component="div" className="text-danger text-sm" />
                                    <span className=" font-sm text-gray-700">Appears below your name at the top of the profile.</span>

                                </Form.Group> */}
                            </div>
                            <hr className="border-gray-300 mb-4 hrb" />
                            {/* Submit Button */}

                            <div className={`absolute bottom-0 left-0 w-full bg-white p-4 border-t border-gray-300 flex rounded-b-lg ${isEditMode ? "justify-between" : "justify-end"}`}>
                                {isEditMode && values && (
                                    <p
                                        className="text-gray-700 font-bold hover:text-base hover:bg-gray-200 px-3 py-1 hover:rounded-md cursor-pointer"
                                        onClick={() => {
                                            setSelectedValue(values);
                                            setShowPopup(true);
                                            handleClose();
                                        }}
                                    >
                                        DELETE
                                    </p>
                                )}

                                <Button type="submit" disabled={isSubmitting} className="btn-explore-white">
                                    Save
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal>

            {showPopup && selectedValue && (
                <ConfirmationPopup
                    isOpen={showPopup}
                    title="Delete This Entry?"
                    message="This will permanently delete the record. This action cannot be undone."
                    onClose={() => setShowPopup(false)}
                    onConfirm={async () => {
                        await handleDelete(selectedValue);
                    }}
                    loading={loading} // Pass loading state to popup
                    showCancelButton={true}
                    onDiscard={false}
                    actionType="delete" // ✅ lowercase string
                />
            )}
            {popupData.isOpen && (
                <ConfirmPopupShareLink
                    isOpen={popupData.isOpen}
                    title={popupData.title}
                    message={popupData.message}
                    onClose={() => setPopupData({ isOpen: false })}
                    onConfirm={() => setPopupData({ isOpen: false })}
                    showCancelButton={false}
                    onDiscard={false}
                />
            )}

            {showPopupC && (
                <ConfirmationPopup
                    isOpen={showPopupC}
                    title="Discard Changes?"
                    message="Are you sure you want to discard all changes?"
                    onClose={() => setShowPopupC(false)}

                    onDiscard={async () => {
                        handleClose();
                        setShowPopupC(false);
                    }}
                    loading={loading}
                    showCancelButton={true}
                    onConfirm={false}
                />
            )}


        </>
    );
};

export default ProfessionalSummary;
