import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row, Form } from "react-bootstrap";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import RatingSubmit from "./RatingSubmit";
import MainHeader from "../../Pages/Header/MainHeader";
import FooterStyle from "../Footer/Footer";
import { IMAGE_PATHS, base64Encode, base64Decode, DOMAIN_URL } from "../../Functions/Constant";
import API from '../../Api/Api';
import ConfirmationPopup from '../../Components/ConfirmationPopup'

const ReviewForm = () => {
    const { id } = useParams();
    const [reviewId, setReviewId] = useState("");
    const [review, setReview] = useState(null);
    const [categorySkills, setCategorySkills] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ratingDataVal, setRatingDataVal] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null); // Store a single selected tag
    const [popupData, setPopupData] = useState({
        isOpen: false,
        title: "",
        message: "",
        onClose: () => { },
        onConfirm: () => { }
    });
    const tagValues = {
        "Friend": 1,
        "Teacher": 2,
        "Hr": 3,
        "Colleague": 4,
        "Manager": 5
    };

    const tags = review?.company_name
        ? ["Hr", "Colleague", "Manager"]
        : review?.school_name
            ? ["Teacher", "Friend"]
            : [];

    const fetchDraftReview = async (id) => {
        try {
            const payload = {
                review_id: id,
            };
            const response = await API.post(`/user/getuser-for-rating`, payload);
            if (response.users.length > 0) {
                const user = response.users[0];
                setReview(user);
                console.log("user:", user);
                fetchSkills(user.type);
            }
            console.log("Response:", response);
            // if (response.success) {
            //     setReview(response.review);
            // } else {
            //     setError("Review not found.");
            // }
        } catch (error) {
            //setError("Failed to load review.");
            console.error("Error fetching review:", error);
        } finally {
            //setLoading(false);
        }
    };
    const fetchSkills = async (type) => {
        try {
            if (type == 1) {
                const response = await API.get(`/autocomplete/skills/professional`);
                if (response) {
                    setCategorySkills(response);
                } else {
                    setError("Skills not found.");
                }
            } else {
                const response = await API.get(`/autocomplete/skills/academic`);
                if (response) {
                    setCategorySkills(response);
                } else {
                    setError("Skills not found.");
                }
            }

        } catch (error) {
            setError("Failed to load skills.");
            console.error("Error fetching skills:", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {

        setReviewId(base64Decode(id));
        fetchDraftReview(base64Decode(id));
        console.log("Review ID:", id, reviewId, base64Decode(id));
        // const fetchReview = async () => {
        //     try {
        //         const response = await API.get(`/review_form/${id}`);
        //         if (response.success) {
        //             setReview(response.review);
        //         } else {
        //             setError("Review not found.");
        //         }
        //     } catch (error) {
        //         setError("Failed to load review.");
        //         console.error("Error fetching review:", error);
        //     } finally {
        //         setLoading(false);
        //     }
        // };

        // fetchReview();
    }, [id]);

    // ✅ Validation Schema using Yup
    const validationSchema = Yup.object().shape({
        selectedTag: Yup.string().required("Please select a valid role."),
        reviewText: Yup.string()
            .test("word-count", "Review must not exceed 300 words", (value) => {
                if (!value) return true; // Skip if empty (required will catch it)
                const wordCount = value.trim().split(/\s+/).length;
                return wordCount <= 300;
            })
            .required("Review is required"),
        languageProficiency: Yup.string().required("Please select an option"),
        agreeTerms: Yup.boolean()
            .oneOf([true], "You must agree to the terms before submitting"),
    });

    const setRatingData = (data) => {
        setRatingDataVal(data);
    };
    const submitReview = async (values) => {

        const payload = {
            review_id: reviewId,
            review_title: values.reviewTitle,
            review_text: values.reviewText,
            review_type_show: values.languageProficiency === "yes" ? 1 : 0,
            ratings: ratingDataVal,
            review_type: review.type,
            recipient_id: review.user_id,
            selectedTag: tagValues[values.selectedTag]
        };
        const response = await API.post(`/reviews/addreview`, payload);
        console.log("Response:", response);
        console.log("Payload:", payload);

        setPopupData({
            isOpen: true,
            title: "Success!",
            message: "Your details have been updated successfully.",
            onConfirm: () => {
                setPopupData({ isOpen: false });
                window.location.href = '/write_review';
            },
            onClose: () => setPopupData({ isOpen: false })
        });
    };
    return (
        <div>
            <MainHeader />
            <section className="pb-12 px-4 pt-[120px] custm-bg">
                <Container>
                    <Row className=" p-6 pt-0 relative  ">
                        {/* LEFT FIXED PROFILE BOX */}
                        <Col lg={4} md={12} sm={12} className="custm-bg">
                            <div className="sticky top-[70px]">
                                <div className="">

                                    <h3 className="text-[32px] font-bold text-black mb-2 leading-[1.5]">
                                        Share Your Review!
                                    </h3>
                                    <div >
                                        {review && review.profile_picture != null ? (
                                            <img
                                                src={DOMAIN_URL + review.profile_picture}
                                                className="w-[80px] h-[80px] rounded-full object-cover border border-gray-300"
                                            />
                                        ) : (<img
                                            src={IMAGE_PATHS.USER}
                                            className="w-[80px] h-[80px] rounded-full object-cover border border-gray-300"
                                        />)}
                                        {review && review.name != null ? (<h4 className="text-lg font-bold text-gray-900 mb-0">
                                            {review.name}
                                        </h4>
                                        ) : (<></>)}
                                        {
                                            review && review.type == 1 ? (<><p className="text-gray-700 leading-[1.6]">
                                                {review.company_name}
                                            </p>
                                                <p className="text-gray-700 leading-[1.2]">{review.c_start_year}</p>
                                                <p className="text-gray-700 ">{review.city}</p>
                                                <span className="text-xs bg-black text-white px-2 py-1 font-medium">
                                                    Professional Review
                                                </span></>) : (<>
                                                    {review && review.school_name != null ? (
                                                        <p className="text-gray-700 leading-[1.6]">
                                                            {review.school_name}
                                                        </p>
                                                    ) : (<></>)}

                                                    {review && review.start_year > 0 ? (<p className="text-gray-700 leading-[1.2]">{review.start_year}</p>) : (<></>)}
                                                    {review && review.city != null ? (<p className="text-gray-700 ">{review.city}</p>) : (<></>)}
                                                    {review && (<span className="text-xs bg-black text-white px-2 py-1 font-medium">
                                                        Academic Review
                                                    </span>)}
                                                </>)
                                        }


                                    </div>
                                </div>
                            </div>
                        </Col>

                        {/* RIGHT SCROLLABLE FORM */}
                        <Col lg={8} md={12} sm={12} className="bg-white p-3 border border-gray-300 rounded-lg shadow-md ">
                            <section className="switch-tabs py-6 px-6 pt-0">

                                <h3 className="text-[20px] font-bold text-black mb-0  bor-q-h leading-[1.5 ] py-6 px-6 pl-0 pt-0">
                                    Rate the skills
                                </h3>

                                <RatingSubmit
                                    theme="accordion-style-05"
                                    className=""
                                    themeColor="light"
                                    data={categorySkills}
                                    setRationData={setRatingData}
                                />
                            </section>

                            <div className="py-6 px-6">
                                <Formik
                                    initialValues={{
                                        selectedTag: "",
                                        reviewText: "",
                                        reviewTitle: "",
                                        languageProficiency: "",
                                        agreeTerms: false,
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={submitReview}
                                >
                                    {({ values, errors, touched, setFieldValue, setTouched }) => (
                                        <FormikForm>
                                            {/* Write your review */}
                                            <div className="mb-6 leading-[1]">
                                                <label className="text-[20px] font-bold text-black mb-2">
                                                    Write your review
                                                </label>
                                                <Field
                                                    as="textarea"
                                                    name="reviewText"
                                                    style={{ resize: "none" }}
                                                    className="w-full border border-gray-300 rounded-lg p-3 text-gray-900 focus:ring-2 focus:ring-black focus:outline-none"
                                                    placeholder="Write your review..."
                                                    rows={4}
                                                />
                                                <div className="relative">
                                                    <div className="flex flex-col">
                                                        <ErrorMessage
                                                            name="reviewText"
                                                            component="div"
                                                            className="text-red-600 text-sm mt-0"
                                                        />
                                                        <div className="absolute right-0 text-sm text-gray-500">
                                                            {values.reviewText.trim().split(/\s+/).filter(Boolean).length}/300 max words
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-6">
                                                <label className="text-[20px] font-bold text-black mb-2">Who are you?</label>
                                                <div className="flex items-center flex-wrap gap-3">
                                                    {tags.map((tag, index) => (
                                                        <label key={index} className="cursor-pointer">
                                                            <input
                                                                type="radio"
                                                                name="selectedTag"
                                                                value={tag}
                                                                className="hidden"
                                                                onChange={() => {
                                                                    setFieldValue("selectedTag", tag);
                                                                    setTouched((prev) => ({ ...prev, selectedTag: true })); // ✅ Correct way
                                                                }}
                                                            />
                                                            <div
                                                                className={`px-3 py-1 text-sm font-medium border border-gray-300 rounded-full transition-all duration-200 
                        ${values.selectedTag === tag ? "bg-gray-300 text-gray-900" : "bg-white text-gray-600 hover:bg-gray-200"}`}
                                                            >
                                                                {tag}
                                                            </div>
                                                        </label>
                                                    ))}
                                                </div>
                                                <ErrorMessage
                                                    name="selectedTag"
                                                    component="div"
                                                    className="text-red-600 text-sm mt-1"
                                                />
                                            </div>




                                            {/* Radio Buttons */}
                                            <div className="mb-10">
                                                <label className="text-[20px] font-bold text-black mb-2">
                                                    Choose how to submit your review
                                                </label>
                                                <div className="flex space-x-6">
                                                    <label className="flex items-center space-x-2">
                                                        <Field
                                                            type="radio"
                                                            name="languageProficiency"
                                                            value="yes"
                                                            className="w-5 h-5"
                                                        />
                                                        <span className="black-class"><span className="font-bold">With Your Name </span>(Your identity will be visible)</span>
                                                    </label>
                                                </div>
                                                <div className="flex space-x-6">
                                                    <label className="flex items-center space-x-2">
                                                        <Field
                                                            type="radio"
                                                            name="languageProficiency"
                                                            value="no"
                                                            className="w-5 h-5"
                                                        />
                                                        <span className="black-class"><span className="font-bold">Anonymously</span> (Your identity will remain hidden)</span>
                                                    </label>
                                                </div>
                                                <ErrorMessage
                                                    name="languageProficiency"
                                                    component="div"
                                                    className="text-red-600 text-sm mt-0"
                                                />

                                            </div>

                                            {/* Checkbox Agreement */}
                                            <div className="mb-10">
                                                <div className="flex items-start">
                                                    <Field
                                                        type="checkbox"
                                                        name="agreeTerms"
                                                        className="w-5 h-5 mt-1 border-gray-400 focus:ring-2 focus:ring-black check-l"
                                                    />
                                                    <label className="ml-3 text-gray-700 leading-[1.7]">
                                                        I certify that this review is based on my own experience.
                                                        I understand that fake reviews are not tolerated.{" "}
                                                        <a href="/term_policy" className="text-black font-bold underline" target="_blank"
                                                            rel="noopener noreferrer">
                                                            Learn more about review fraud.
                                                        </a>
                                                    </label>
                                                </div>
                                                <ErrorMessage
                                                    name="agreeTerms"
                                                    component="div"
                                                    className="text-red-600 text-sm mt-1"
                                                />
                                            </div>


                                            {/* Submit Button */}
                                            <button className="w-[40%] btn-explore-yellow py-3 font-bold !text-[15px] !rounded-[27px]">
                                                Submit review
                                            </button>

                                        </FormikForm>
                                    )}
                                </Formik>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {popupData.isOpen && (
                <ConfirmationPopup
                    isOpen={popupData.isOpen}
                    title={popupData.title}
                    message={popupData.message}
                    onClose={() => setPopupData({ isOpen: false })}
                    onConfirm={popupData.onConfirm}
                    showCancelButton={false}
                />
            )}
            <FooterStyle theme="light" className="bg-black text-[#828282]" />
        </div>
    );
};

export default ReviewForm;
