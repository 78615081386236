import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Loader from "./Loader";

const ImagePopup = ({ imageUrl, onClose }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (imageUrl) {
            setLoading(true);
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => setLoading(false);
        }
    }, [imageUrl]);

    return (
        <Modal show={!!imageUrl} onHide={onClose} centered backdrop="static">
            <Modal.Body className="bg-black d-flex justify-content-center align-items-center p-0 relative">
                {/* Close Button */}
                <button
                    className="absolute top-3 right-3 w-10 h-10 flex items-center justify-center bg-white text-black rounded-full shadow-md hover:bg-gray-200 transition"
                    onClick={onClose}
                >
                    <svg
                        className="svg-icon"
                        style={{
                            height: "28px",
                            verticalAlign: "middle",
                            fill: "currentColor",
                            overflow: "hidden",
                        }}
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M704.28672 309.20704l28.95872 28.9792L334.6432 736.78848l-28.95872-28.9792z" fill="#231815" />
                        <path d="M341.03296 315.5968l398.60224 398.60224-28.95872 28.95872-398.60224-398.60224z" fill="#231815" />
                    </svg>
                </button>

                {/* Loader when image is loading */}
                {loading ? (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50 z-50">
                        <Loader />
                    </div>
                ) : (
                    <img src={imageUrl} alt="Preview" className="img-fluid mw-100 mh-100 object-fit-contain" />
                )}
            </Modal.Body>
        </Modal>
    );
};

export default ImagePopup;
