// constants.js
// export const BASE_URL = "http://internaltesting.fourmodules.com:3005/bossisalwaysright/reactproject";
export const BASE_URL = "https://jobcritics.com";
// export const JS_PATHS = {
//     MAIN_JS: `${BASE_URL}/assets/js/main.js`,
// };

export const IMAGE_PATHS = {
                ///// HOMEPAGE & TAKE TEST //////////
    LOGO: `${BASE_URL}/assets/img/logoFav/biar_logo.webp`,
    FIRSTBG: `${BASE_URL}/assets/img/biar/firstBg.webp`,
    SECONDBG: `${BASE_URL}/assets/img/biar/BKGRND2.webp`,
    FIRST: `${BASE_URL}/assets/img/biar/1.webp`,
    SECOND: `${BASE_URL}/assets/img/biar/2.webp`,
    THRID: `${BASE_URL}/assets/img/biar/3.webp`,
    FOURTH: `${BASE_URL}/assets/img/biar/4.webp`,
    GOOGLE: `${BASE_URL}/assets/img/biar/google.webp`,
    IN: `${BASE_URL}/assets/img/biar/in.webp`,
    USER: `${BASE_URL}/assets/img/biar/user.webp`,
    BUILDING: `${BASE_URL}/assets/img/biar/building.webp`, 
    SCHOOL: `${BASE_URL}/assets/img/biar/school.webp`,       
    LOADER: `${BASE_URL}/assets/img/biar/loader.gif`, 
    WELCOME: `${BASE_URL}/assets/img/biar/welcome.jpg`,
        
};
export const DOMAIN_URL = 'https://assets.jobcritics.com/jobcritics-api';


export const base64Encode = (data) => {
    return btoa(data);
};

export const base64Decode = (data) => {
    return atob(data);
};
// ✅ Function to get "st", "nd", "rd", or "th" suffix
function getDaySuffix(day) {
    if (day > 3 && day < 21) return "th"; // Covers 4th to 20th
    switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
}
export const formatDate  = (date)=> {
    const day = date.getDate();
    const suffix = getDaySuffix(day);
    const month = date.toLocaleString("en-GB", { month: "long" });
    const year = date.getFullYear();
    
    return `${day}${suffix} ${month} ${year}`;
}

