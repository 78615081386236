import React, { useState } from "react";

const SetupSlider = () => {
    const slides = [
        {
            title: "Set Up Your Account",
            description: "Complete your profile to receive ratings and reviews.",
            buttonText: "Update profile",
            redirectTo: "/employee_detail",
        },
        {
            title: "Update Your Experience",
            description: "Get ratings from colleagues and employers.",
            buttonText: "Update Experience",
            redirectTo: "/employee_detail?reviews",
        },
        {
            title: "Update Your Education",
            description: "Get ratings from friends and teachers.",
            buttonText: "Update Education",
            redirectTo: "/employee_detail?academic",
        },
        {
            title: "Update Your Photo",
            description: "Get recognized by your connections for ratings and reviews.",
            buttonText: "Update photo",
            redirectTo: "/profile",
        },
    ];
    

    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    const handleButtonClick = () => {
        window.location.href = slides[currentSlide].redirectTo;
    };

    return (
        <div className="flex mb-3 bg-white p-4 border border-gray-300 rounded-lg relative shadow-md">
            <div className="w-full">
                {/* Header */}
                <div className="flex justify-between items-center mb-2">
                    <h2 className="text-[20px] text-black font-semibold !mb-[0px]">
                        {slides[currentSlide].title}
                    </h2>
                    <span className="text-[20px] text-black">
                        {`${currentSlide + 1}/${slides.length} complete`}
                    </span>
                </div>

                {/* Image Placeholder */}
                <div className="w-full h-[90px] bg-gray-100 rounded-md mb-3 flex items-center justify-center">
                    <span className="text-xs text-gray-400">[Image Here]</span>
                </div>

                {/* Description */}
                <p className="text-[15px] text-gray-700 mb-3">
                    {slides[currentSlide].description}
                </p>

                {/* Button */}
                <button
                    onClick={handleButtonClick}
                    className="btn-explore-white"
                >
                    {slides[currentSlide].buttonText}
                </button>
            </div>

            {/* Arrow Button */}
            <button
                onClick={nextSlide}
                className="absolute top-1/2 -translate-y-1/2 right-2 w-8 h-8 flex items-center justify-center bg-white border border-gray-300 rounded-full bks"
            >
                <i className="fa fa-chevron-right text-gray-700 text-xs"></i>
            </button>
        </div>
    );
};

export default SetupSlider;
